import React, { useState, useEffect } from "react";
import { useHistory, useParams, Redirect, Link } from "react-router-dom";
import Axios from "axios";
import env from "../env";
import auth from "../Auth/authinfo";
import logo from "../assets/images/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LangSelector from "../lang/LangSelector";
import Text from "../lang/Text";
import { confirmAlert } from "react-confirm-alert";
import { isChrome } from "react-device-detect";
import $ from "jquery";
import DownloadChrome from "./Common/DownloadChrome";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [inProgress, setInProgeress] = useState(false);

  let history = useHistory();

  useEffect(() => {
    // Axios.get(env.baseUrl + "/class/info/" + classId).then(({ data }) => {
    // 	console.log(data);
    // 	if(data.id === undefined){
    // 		history.push('/')
    // 	}
    // }).catch(e => console.log(e));
  });
  let { classId } = useParams();
  if (auth.isAuthenticated()) {
    if (auth.isStudent()) {
      history.push("/student/start/" + classId);
    } else if (auth.isTeacher()) {
      history.push("/teacher/start/" + classId);
    }
  }
  let submitLoginForm = (e) => {
    e.preventDefault();
    if (!isChrome) {
      $("#at-downloadChrome").modal("show");
      return 0;
    }

    setInProgeress(true);
    Axios.post(env.baseUrl + "/login", {
      email: email,
      password: password,
      classId: classId,
    })
      .then(({ data }) => {
        setInProgeress(false);
        if (data.status_code === 200) {
          auth.authenticate(data.token, data.user);
          console.log(auth.isStudent());
          if (auth.isStudent()) {
            history.push("/student/start/" + classId);
          } else if (auth.isTeacher()) {
            history.push("/teacher/start/" + classId);
          } else {
            console.log(data);

            toast.error("Not A valid user", {
              position: toast.POSITION.TOP_RIGHT,
            });
            sessionStorage.clear();
          }
        } else if (data.status_code === 305) {
          toast.error("You are not enrolled in this class", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (data.status_code === 409) {
          toast.error("You are already logged in", {
            position: toast.POSITION.TOP_RIGHT,
          });

          confirmAlert({
            title: "You are already logged in",
            message: "Do you want to logout from other devices and login here",
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                  setInProgeress(true);
                  Axios.post(env.baseUrl + "/logout/from/others", {
                    email: email,
                    password: password,
                    classId: classId,
                  }).then(({ data }) => {
                    setInProgeress(false);
                    if (data.status_code === 200) {
                      auth.authenticate(data.token, data.user);
                      console.log(auth.isStudent());
                      if (auth.isStudent()) {
                        history.push("/student/start/" + classId);
                      } else if (auth.isTeacher()) {
                        history.push("/teacher/start/" + classId);
                      } else {
                        console.log(data);

                        toast.error("Not A valid user", {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                        sessionStorage.clear();
                      }
                    }
                  });
                },
              },
              {
                label: "No",
                onClick: () => {
                  return false;
                },
              },
            ],
          });
        } else {
          toast.error("Credentials Don't match our records", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) => setInProgeress(false));
  };

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  const loginContent = (
    <>
      <div className="at-pagehead">
        <div className="at-logoimg">
          <img src={logo} alt="logo imag" />
        </div>
        <LangSelector />
      </div>
      <div className="my-auto">
        <div className="at-pageheading">
          <h3>
            <em>Portale Corsi</em> <Text tid="welcomeTo" />
          </h3>
          {!isChrome ? (
            <span style={{ color: "black" }}>
              <Text tid="useChromeBrowser" />
              &nbsp;
              <img
                style={{ height: "30px" }}
                src={require("../assets/images/Google_Chrome_icon_(2011).png")}
              />
            </span>
          ) : (
            ""
          )}
        </div>
        <div className="at-signinpageform">
          <form className="at-formtheme" onSubmit={(e) => submitLoginForm(e)}>
            <legend>
              <Text tid="useYouraccount" />
            </legend>
            <fieldset>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Please enter email.."
                />
                <i className="icon-user"></i>
              </div>
              <div className="form-group">
                <label>
                  <Text tid="password" />
                </label>
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="••••••••••"
                />
                <i className="icon-lock"></i>
              </div>
              <div className="form-group">
                <button className="at-signin" type="submit">
                  <Text tid="signIn" />
                  {inProgress ? (
                    <img src={require("../assets/loader.gif")} />
                  ) : (
                    ""
                  )}
                </button>
              </div>
              <div className="at-forgotpasswordarea">
                <span>
                  <em>
                    <Text tid="youforgotPassword"></Text>:
                  </em>{" "}
                  <Link className="at-btnforgot" to="forget-password">
                    Forget Password
                  </Link>
                </span>
              </div>
              <div className="form-group">
                <span className="at-btnsingup">
                  <Text tid="dontHaveAccount" />
                  <a href="http://www.portalecorsi.com" target="_blank">
                    {" "}
                    <Text tid="signUp" />
                  </a>
                </span>
              </div>
            </fieldset>
          </form>
          <span className="at-copyright">© Copyright 2020</span>
        </div>
      </div>
    </>
  );
  return (
    <div>
      <ToastContainer />
      <DownloadChrome />
      <div className="at-signinpageholder">
        <div className="at-singinpageimg"></div>

        {dimensions.width <= 991 ? (
          <div className="container">
            <div className="row">
              <div className="at-singinpage overflow-hidden col-12 d-flex flex-column">
                {loginContent}
                {/* <div className="at-pagehead">
                  <div className="at-logoimg">
                    <img src={logo} alt="logo imag" />
                  </div>
                  <LangSelector />
                </div>
                <div className="my-auto">
                  <div className="at-pageheading">
                    <h3>
                      <em>Portale Corsi</em> <Text tid="welcomeTo" />
                    </h3>
                    {!isChrome ? (
                      <span style={{ color: "black" }}>
                        <Text tid="useChromeBrowser" />
                        &nbsp;
                        <img
                          style={{ height: "30px" }}
                          src={require("../assets/images/Google_Chrome_icon_(2011).png")}
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="at-signinpageform">
                    <form
                      className="at-formtheme"
                      onSubmit={(e) => submitLoginForm(e)}
                    >
                      <legend>
                        <Text tid="useYouraccount" />
                      </legend>
                      <fieldset>
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Please enter email.."
                          />
                          <i className="icon-user"></i>
                        </div>
                        <div className="form-group">
                          <label>
                            <Text tid="password" />
                          </label>
                          <input
                            type="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="••••••••••"
                          />
                          <i className="icon-lock"></i>
                        </div>
                        <div className="form-group">
                          <button className="at-signin" type="submit">
                            <Text tid="signIn" />
                            {inProgress ? (
                              <img src={require("../assets/loader.gif")} />
                            ) : (
                              ""
                            )}
                          </button>
                        </div>
                        <div className="at-forgotpasswordarea">
                          <span>
                            <em>
                              <Text tid="youforgotPassword"></Text>:
                            </em>{" "}
                            <Link className="at-btnforgot" to="forget-password">
                              Forget Password
                            </Link>
                          </span>
                        </div>
                        <div className="form-group">
                          <span className="at-btnsingup">
                            <Text tid="dontHaveAccount" />
                            <a
                              href="http://www.portalecorsi.com"
                              target="_blank"
                            >
                              {" "}
                              <Text tid="signUp" />
                            </a>
                          </span>
                        </div>
                      </fieldset>
                    </form>
                    <span className="at-copyright">© Copyright 2020</span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        ) : (
          <div className="at-singinpage">
            {loginContent}
            {/* <div className="at-pagehead">
              <div className="at-logoimg">
                <img src={logo} alt="logo imag" />
              </div>
              <LangSelector />
            </div>
            <div className="my-auto">
              <div className="at-pageheading">
                <h3>
                  <em>Portale Corsi</em> <Text tid="welcomeTo" />
                </h3>
                {!isChrome ? (
                  <span style={{ color: "black" }}>
                    <Text tid="useChromeBrowser" />
                    &nbsp;
                    <img
                      style={{ height: "30px" }}
                      src={require("../assets/images/Google_Chrome_icon_(2011).png")}
                    />
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="at-signinpageform">
                <form
                  className="at-formtheme"
                  onSubmit={(e) => submitLoginForm(e)}
                >
                  <legend>
                    <Text tid="useYouraccount" />
                  </legend>
                  <fieldset>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Please enter email.."
                      />
                      <i className="icon-user"></i>
                    </div>
                    <div className="form-group">
                      <label>
                        <Text tid="password" />
                      </label>
                      <input
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="••••••••••"
                      />
                      <i className="icon-lock"></i>
                    </div>
                    <div className="form-group">
                      <button className="at-signin" type="submit">
                        <Text tid="signIn" />
                        {inProgress ? (
                          <img src={require("../assets/loader.gif")} />
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                    <div className="at-forgotpasswordarea">
                      <span>
                        <em>
                          <Text tid="youforgotPassword"></Text>:
                        </em>{" "}
                        <Link className="at-btnforgot" to="forget-password">
                          Forget Password
                        </Link>
                      </span>
                    </div>
                    <div className="form-group">
                      <span className="at-btnsingup">
                        <Text tid="dontHaveAccount" />
                        <a href="http://www.portalecorsi.com" target="_blank">
                          {" "}
                          <Text tid="signUp" />
                        </a>
                      </span>
                    </div>
                  </fieldset>
                </form>
                <span className="at-copyright">© Copyright 2020</span>
              </div>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
