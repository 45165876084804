import React,{useState,useEffect} from 'react';
import LangSelector from '../lang/LangSelector';
import Text from '../lang/Text';
import Axios from 'axios';
import env from '../env.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NotFound = (props) => {

	const [search,setSearch] = useState('');
	const [classes,setClasses] = useState([]);

	let changeSearchListner = async (e) => {
		let value = e.target.value;
		setSearch(value);
		if(value != ''){
			await Axios.get(env.baseUrl + "/search/class?search=" + value).then(({ data }) => {
				
				console.log(value,data);
				setClasses(data.data)
			}).catch(e => console.log(e));

		}else{
			setClasses([])
		}
	}

	useEffect( () => {
		if(localStorage.getItem('passwordUpdated')){
			localStorage.removeItem('passwordUpdated');
			toast.success('Password updated successfully', {
				position: toast.POSITION.TOP_RIGHT
			  });
		}
	},[])
	
    return (
        <div class="at-signinpageholder at-errorpageholder at-searchpagearea">
			<figure className="at-dotsbg">
				<img src={require('../assets/images/errordots-bg.png')} alt="logo imag"/>
			</figure>
			<div class="at-pagehead">
				<div class="at-logoimg">
					<img src={require('../assets/images/logo.png')} alt="logo imag"/>
				</div>
				{/* <LangSelector /> */}
			</div>
			<ToastContainer />
			<div className="at-searchcontentarea">
				<div className="at-searchcontents">
					<div className="at-description">
						<p><strong>CORSI.STREAM</strong> by Portalecorsi <span>Platform For <strong>Online Courses</strong></span></p>
					</div>
					<form className="at-formtheme rt-formsearchpage">
						<fieldset>
							<div className="form-group">
								<input type="search"  className="form-control" value={search} placeholder="Insert a course link" onChange={e => changeSearchListner(e)}/>
								<button className="at-btn" type="button">Search</button>
							</div>
							{/* Search Result Open Close*/}
							{classes.length > 0 ?  (<div className="at-searchresults"><ul> {classes.map( classs => {
								return (<li><a href={env.appUrl+classs.slug} target="_blank"><span>{classs.title}</span></a></li>)})} </ul></div>) : ''}
							
						</fieldset>
					</form>
					<span className="at-likeemail">
						Would be like to use this platform? Contact us <a href="javascript:void(0);">info@potalecorsi.com</a>
					</span>
				</div>
				<figure className="at-searchimg">
					<img src={require('../assets/images/search-page-img.png')} alt="logo imag"/>
				</figure>
			</div>
				
				{/* 
			{/* <div class="at-singinpageimg">
				<div class="at-pagehead">
					<div class="at-logoimg">
						<img src={require('../assets/images/logo.png')} alt="logo imag"/>
					</div> */}
					{/* <LangSelector /> */}
				{/* </div>
				<figure className="at-newsingimage">
					<img src={require('../assets/images/corsi.png')} alt="Signin Image"/>
				</figure>
			</div> */}
			{/* <div class="at-singinpage"> */}
				{/* <div class="at-pagehead">
					<div class="at-logoimg">
						<img src={require('../assets/images/logo.png')} alt="logo imag"/>
					</div>
					<LangSelector />
				</div> */}
				{/* <div class="at-errorcontent">
					<figure>
						<img src={require('../assets/images/errorbg.png')} alt="error bg img"/>
						<figcaption>
							<span class="at-errorimg"><img src={require('../assets/images/eeror-img.png')} alt="error image"/>
                            </span>
							<h4> <span><Text tid="sorry"/>,</span> <Text tid="linkNotAvailable" /></h4>
							<em><Text tid="openValidLessonlink"/></em>
						</figcaption>
					</figure>
				</div> */}
				{/* <a class="at-signin" href="javascript:void(0);">Go Back</a> */}
				{/* <span class="at-copyright">© Copyright 2020</span> */}
			{/* </div> */}
		</div>
	);
	
	/* return (
        <div class="at-signinpageholder at-errorpageholder">
			<div class="at-singinpageimg"></div>
			<div class="at-singinpage">
				<div class="at-pagehead">
					<div class="at-logoimg">
						<img src={require('../assets/images/logo.png')} alt="logo imag"/>
					</div>
					<LangSelector />
				</div>
				<div class="at-errorcontent">
					<figure>
						<img src={require('../assets/images/errorbg.png')} alt="error bg img"/>
						<figcaption>
							<span class="at-errorimg"><img src={require('../assets/images/eeror-img.png')} alt="error image"/>
                            </span>
							<h4> <span><Text tid="sorry"/>,</span> <Text tid="linkNotAvailable" /></h4>
							<em><Text tid="openValidLessonlink"/></em>
						</figcaption>
					</figure>
				</div>
				<a class="at-signin" href="javascript:void(0);">Go Back</a>
				<span class="at-copyright">© Copyright 2020</span>
			</div>
		</div>
    ); */
};

export default NotFound;