import React from 'react';
import auth from '../../Auth/authinfo'
import Avatar from 'react-avatar';
import Axios from 'axios';
import env from '../../env'
import { useParams } from "react-router-dom";

const ProfileComponent = ({endClassPublish}) => {

    const { classId } = useParams();
    function handleLogout(e) {
        e.preventDefault();

        Axios.get(env.baseUrl + "/logout", {
			'headers': {
				Authorization: 'Bearer ' + auth.authToken(),
				'content-type': 'multipart/form-data'
			},
		}).then(({ data }) => {
            if(endClassPublish != undefined){
                console.log('ended');
                endClassPublish()
            }
			if(classId !== undefined){
                window.location.replace('/'+classId)
            }else {
                window.location.replace('/')            
            }

            window.sessionStorage.clear();
		}).catch(e => console.log(e));

        
        
    }

    return (
        <div className="at-userdropdown">
            <span>{auth.username()}</span>
            {/* <figure className="at-userimg"><Avatar size="50px" src={""} name={auth.username()} /></figure> */}
            
            <a href='#' className='logout-ico' onClick={handleLogout}>
                <i className="icon-switch" aria-hidden="true"></i>
            </a>

        </div>
    );
};

export default ProfileComponent;