import auth from '../../Auth/authinfo'

let reducer = (state, action) => {
    let newState = [];
    switch (action.type) {
        case 'increment':
            return state + 1;
        case 'decrement':
            return state - 1;
        case 'addMembers':
            action.data.map((member) => {					
                if(member.id === auth.userId()){						
                    member.selfUser = 1;
                    member.online = 1;
                }
                newState.push(member)
            })				
            return state.concat(newState);
        case 'memberOnline':
            var check = true;  
            state.map((member) => {
                if(member.id === parseInt(action.data.id)){
                    check = false;
                    member.online = 1;
                }
                newState.push(member)
            });
            if(check){
                action.data.online = 1;
                newState.push(action.data)
            }
            return newState;

        case 'MEMBER_IN_PROGRESS':
            state.map((member) => {
                if(member.id === parseInt(action.id)){
                    member.inProgress = 1;
                }
                newState.push(member)
            });
            return newState;
        case 'MEMBER_IN_PROGRESS_STOP':
            state.map((member) => {
                if(member.id === parseInt(action.id)){
                    member.inProgress = 0;
                }
                newState.push(member)
            });
            return newState;
        case 'memberStreamStatus':
            state.map((member) => {
                if(member.id === parseInt(action.data.id)){
                    member.streamingStatus = action.data.status;
                    if(action.data.audio != undefined && action.data.video != undefined){
                        member.audioStatus = action.data.audio;
                        member.videoStatus = action.data.video;
                    }  
                    if(action.data.publishedStream != undefined){
                        member.publishedStream = action.data.publishedStream;
                        // member.streamVideoStatus = action.data.video;
                    }                  

                }
                newState.push(member)
            });
            return newState;
        case 'streamingAudioStatus':
            state.map((member) => {
                if(member.id === parseInt(action.data.id)){
                    if(action.data.audio != undefined){
                        member.streamAudioStatus = action.data.audio;
                        // member.streamVideoStatus = action.data.video;
                    }

                    if(action.data.video != undefined){
                         member.streamVideoStatus = action.data.video;
                    }

                    if(action.data.publishedStream != undefined){
                        member.publishedStream = action.data.publishedStream;
                        // member.streamVideoStatus = action.data.video;
                    }
                }
                newState.push(member)
            });
            return newState;
        case 'globalMicOn':
            state.map((member) => {
                if(member.id === parseInt(action.data.id)){
                    if(member.streamAudioStatus == true){
                        action.data.webrtc.unmuteLocalMic()
                    }             
                }
            });
            return state;
        case 'globalMicOff':
            state.map((member) => {
                if(member.id === parseInt(action.data.id)){
                    if(!member.onBoarded){
                        action.data.webrtc.muteLocalMic()
                    }             
                }
            });
            return state;
        case 'memberOffline':
                state.map((member) => {
                    if(member.id === parseInt(action.data.id)){
                        member.online = 0;
                        member.streamAudioStatus = false;
                        member.publishedStream = false;
                        member.audioStatus = false;
                        member.videoStatus = false;
                        member.publish = false;
                        member.streamingStatus = 0;
                    }
                    newState.push(member)
                });
                return newState;
        case 'ON_BOARD_REQUEST_STATUS':
            state.map((member) => {
                if(member.id === parseInt(action.data.id)){
                    member.onBoardRequestInProgress = action.data.status;
                }
                newState.push(member)
            });
            return newState;
        case 'zoomUser':
            state.map((member) => {
                if(member.id === parseInt(action.id)){
                    if(member.zoom == 1){
                        member.zoom = 0;
                    }else{
                        member.zoom = 1;
                    }
                }else{
                    member.zoom = 0;
                }
                newState.push(member)
            });
            console.log(newState);
            
            return newState;
        case 'questionUser':
            state.map((member) => {
                if(member.id === action.data.id){
                    member.askquestion = 1;
                    member.askQuestionIcon = action.icon
                }
                newState.push(member)					
            });
            newState.sort(function(x,y){                 
                return x.id == action.data.id ? -1 : y.id == action.data.id ? 1 : 0; 
            });
            return newState;
        case 'questionUserRemove':
            state.map((member) => {
                if(member.id === action.data.id){
                    member.askquestion = 0;
                }
                newState.push(member)					
            });

            newState.sort(function(x,y){
                return x.role_id == 2 ? -1 : y.role_id == 2 ? 1 : 0; 
            });

            return newState;
        case 'setState':
            console.log(action,'reducer')
            return action.data
        case 'studentStream': 
            if(action.data !== 'listner'){
                let publishResponse = action.webrtc.publish(action.metadata.streamId)
                if(publishResponse !== 0){
                    if(!action.metadata.video){
                        setTimeout(() => {
                            action.webrtc.turnOffLocalCamera();
                        },500)
                    }else{
                        action.webrtc.turnOnLocalCamera();
                    }
                }
            }
            return state = action.data;
        case 'streamId':
            return action.streamId
        case 'setActiveStreamUserOnline':
            state.map((member) => {        
                if(member.id === action.data){
                    member.activeStream = 1;
                }else{
                    member.activeStream = 0;
                }
                newState.push(member)	
            }); 
            return newState;  
        case 'setOnbaordUser':
            state.map((member) => {        
                if(member.id == action.id){
                    member.onBoarded = action.status; 
                }else{
                    member.onBoarded = false; 
                }
                newState.push(member)	
            }); 
            return newState;     
        case 'studentRemovedFromOnbaord':
            state.map((member) => {        
                if(member.id == action.data.id){
                    if(member.onBoarded){
                        var mediaConstraints = {
                            video: true,
                            audio: true
                        };
                        action.data.webrtc.switchVideoCameraCapture(action.data.streamId, mediaConstraints)
                        if(member.publishedStream != true){
                            action.data.webrtc.stop(action.data.streamId)
                        }

                        setTimeout(() => {
                            if(member.streamVideoStatus == true){
                                action.data.webrtc.turnOnLocalCamera()
                            }else{
                                action.data.webrtc.turnOffLocalCamera()
                            }
                        },1000)

                        setTimeout(() => {
                            if(member.streamAudioStatus == true){
                                action.data.webrtc.unmuteLocalMic()
                            }else{
                                action.data.webrtc.muteLocalMic();
                            }
                        },2000)
                    }
                }
                newState.push(member)	
            }); 
            return newState;                  
        case 'setActiveStreamUserOffline':
            state.map((member) => {            
                member.activeStream = 0;
                member.active = 0;
                newState.push(member)
            });
            
            newState.sort(function(x,y){          
                return x.role_id == 2 ? -1 : y.role_id == 2 ? 1 : 0; 
            });
            return newState;
        case 'SORT_TEACHER_FIRST': 
            state.sort(function(x,y){
                return x.role_id == 2 ? -1 : y.role_id == 2 ? 1 : 0; 
            });
            return state;

        case 'kickOutFromClass':            
            state.map((member,key) => {
                if(member.id !== action.memberId){                    
                    newState.push(member)
                }
            });
            return newState;
        case 'setFullScreen':
            if(action.data == 0){
                return false
            }else{
                return true;
            }
        case 'setMemberStreamStatus':
            return action.state
        default:
            return state;
    }
}

export default reducer;