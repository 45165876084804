import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import $ from "jquery";
import "./assets/js/themefunction";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./style/normalize.css";
import "./style/style.css";
import "./style/icomoon.css";
import "./style/responsive.css";
import Text from "./lang/Text";
import StartLesson from "./Components/Teacher/StartLesson";
import StudentStartLesson from "./Components/Student/StudentStartLesson";
import ClassRoom from "./Components/Teacher/ClassRoom";
import Login from "./Components/Login";
import TeacherPrivateRoute from "./Auth/TeacherPrivateRoute";
import StudentPrivateRoute from "./Auth/StudentPrivateRoute";
import NotFound from "./Components/NotFound";
import { LanguageProvider } from "./Hooks/MultiLanguage/MultiLangContext";
import NoInternet from "./Components/Common/NoInternet";
import ForgotPassword from "./Components/ForgotPassword";
import ChangePassword from "./Components/ChangePassword";
import MaxStreamReached from "./Components/Common/MaxStreamReached";
import { useLocation } from "react-router-dom";

function App() {
  const location = window.location.pathname;
  console.log(location);
  const [extView, setExtView] = useState(false);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    if (
      dimensions.width >= 1081 &&
      dimensions.height <= 800 &&
      location === "/"
    ) {
      setExtView(true);
    } else {
      setExtView(false);
    }
  }, [dimensions]);

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  return (
    <LanguageProvider>
      <div
        id="at-wrapper"
        className={extView ? "ext_view at-wrapper" : "at-wrapper"}
      >
        <NoInternet />
        <MaxStreamReached />
        <Router>
          <Switch>
            <Route exact path="/">
              <NotFound />
            </Route>
            <Route exact path="/forget-password">
              <ForgotPassword />
            </Route>
            <Route exact path="/change-password/:token">
              <ChangePassword />
            </Route>
            <Route exact path="/:classId">
              <Login />
            </Route>
            <TeacherPrivateRoute
              exact
              path="/teacher/start/:classId"
              component={StartLesson}
            />
            <TeacherPrivateRoute exact path="/class/:classId">
              <ClassRoom />
            </TeacherPrivateRoute>
            <StudentPrivateRoute exact path="/student/start/:classId">
              <StudentStartLesson />
            </StudentPrivateRoute>
            <Route exact path="/user/logout">
              {() => {
                sessionStorage.clear();
                return <NotFound />;
              }}
            </Route>
          </Switch>
        </Router>
      </div>
    </LanguageProvider>
  );
}

export default App;
