import React, { useEffect, useState, useReducer, useContext } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import teacherMicOnIcon from '../../assets/images/teacher-mic-on.svg'
import teacherMicOffIcon from '../../assets/images/teacher-mic-off.svg'
import teacherIcon from "../../assets/images/teachers.svg";
import $ from "jquery";
import chatUser from "../../assets/images/class-meates/img-01.png";
import MessageHolder from "./MessageHolder";
import Avatar from "react-avatar";
import auth from "../../Auth/authinfo";
import Pusher from "pusher-js";
import Axios from "axios";
import env from "../../env";
import webRTCAdaptor from "../../Webrtc/webrtc";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClassMemberHolder from "./ClassMemberHolder";
import PermissionModal from "./PermissionModal";
import LangSelector from "../../lang/LangSelector";
import ProfileComponent from "../Common/ProfileComponent";
import Text from "../../lang/Text";
import { LanguageContext } from "../../Hooks/MultiLanguage/MultiLangContext";
import reducer from "../Common/Reducer";
import ClassMemberList from "../Common/ClassMemberList";
import ClassRoomLoader from "../Loader/ClassRoomLoader";
import { confirmAlert } from "react-confirm-alert";
import Tour from "reactour";
import "react-confirm-alert/src/react-confirm-alert.css";
import AntPlayer from "../Common/AntPlayer";

const ClassRoom = () => {
  const [messages, setMessages] = useState([]);
  const [dateToday, setDateToday] = useState("");
  const [message, setMessage] = useState("");
  const [messagePage, setMessagePage] = useState(1);
  const [displayPrevMessageBtn, setDisplayPrevMessageBtn] = useState(false);
  const [displayMessageLoader, setDisplayMessageLoader] = useState(false);
  const [videoStatus, setVideoStatus] = useState("on");
  const [audioStatus, setAudioStatus] = useState("on");
  const [nm, setNm] = useState({});
  const [channel, setChannel] = useState({});
  const [webrtc, setWebrtc] = useState(null);
  const [virtualClass, setVirtualClass] = useState({});
  const [uploadProgress, updateUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [streamStarted, setStreamStarted] = useState(false);
  const [recordingStarted, setRecordingStarted] = useState(false);

  const [classMembers, setClassMembers] = useReducer(reducer, []);
  const [onlineMembers, setOnlineMembersCount] = useReducer(reducer, 0);
  const [streamingMembers, setStreamingMembersCount] = useReducer(reducer, 0);

  const [questionUser, setQuestionuser] = useState({});
  const [studentStreamId, setStudentStreamId] = useState("");
  const [studentStreamEnabled, setStudentStreamStatus] = useState(false);
  const [studentStreamUser, setStudentStreamUser] = useReducer(reducer, {});
  const [loading, setLoading] = useState(true);
  const [selectedChatMember, setSelectedChatMember] = useState(null);
  const [streamMemberRemove, setStreamMemberRemove] = useState({});
  const [displayChatDot, setDisplayChatDot] = useState(false);
  const [initialStreamStart, setInitialStreamStart] = useState(true);
  const [studentStreamVideo, setStudentStreamVideoStatus] = useState(true);
  const [studentStreamAudio, setStudentStreamAudioStatus] = useState(true);

  const [tour, setTour] = useState(false);
  const [fullScreenChat, setFullScreenChat] = useState(false);
  const [screenSharing, setScreenSharing] = useState(false);
  const [globalMicStatus, setGlobalMicStatus] = useState(true);
  const [splitScreenClass, setSplitScreenClass] = useState(
    "at-teacherstudentview"
  );

  const [fullScreen, setFullScreen] = useReducer(reducer, false);
  const [showClasses, setShowClasses] = useState(false);
  const [splitView, setSplitView] = useState(false);

  const languageContext = useContext(LanguageContext);

  let { classId } = useParams();
  let history = useHistory();
  let chatAreaEl;
  // Initial Effect
  useEffect(() => {
    setWebrtc(webRTCAdaptor());
    Axios.get(
      env.baseUrl + "/class/info/" + classId + "?streamCountRefresh=1",
      {
        headers: {
          Authorization: "Bearer " + auth.authToken(),
          "content-type": "multipart/form-data",
        },
      }
    )
      .then(({ data }) => {
        if (data.enrolled_status) {
          setVirtualClass(data.class);
          setClassMembers({ type: "addMembers", data: data.data.classMembers });
          setTimeout(() => {
            setLoading(false);
            setTour(true);
          }, 3000);
        } else {
          history.push("/");
        }
      })
      .catch((e) => console.log(e));

    const pusher = new Pusher(env.PUSHER_APP_KEY, {
      cluster: env.PUSHER_APP_CLUSTER,
      forceTLS: true,
      auth: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + auth.authToken(),
        },
      },
      userId: auth.userId(),
      userInfo: {},
      authEndpoint: env.baseUrl + "/pusher/auth",
    });

    const presenceChannel = pusher.subscribe("presence-class-" + classId);

    presenceChannel.bind("pusher:member_added", function (member) {
      setOnlineMembersCount({ type: "increment" });
      setClassMembers({ type: "memberOnline", data: member.info.userInfo });
      let trigger = {
        type: 'teacherStreamToggle',
        userInfo: auth.userInfo(),
        videoStatus: videoStatus == 'on' || screenSharing == 'on' ? true : false,
      };
      presenceChannel.trigger("client-AskQuestion-" + classId, trigger)
    });

    presenceChannel.bind("pusher:member_removed", function (member) {
      setStreamMemberRemove(member);
      setOnlineMembersCount({ type: "decrement" });
      setClassMembers({ type: "memberOffline", data: member.info.userInfo });
    });

    presenceChannel.bind("pusher:subscription_succeeded", function (members) {
      console.log("success");

      if (members.count > 0) {
        const nMembers = [];

        members.each(function (member) {
          setOnlineMembersCount({ type: "increment" });
          // nMembers.push(member.info.userInfo);
          setClassMembers({ type: "memberOnline", data: member });
        });

        // setClassMembers({ type: 'addMembers', data: nMembers });
      }
    });

    presenceChannel.bind("client-AskQuestion-" + classId, (data, metadata) => {
      console.log("trigger", data);

      if (data.type === "studentTurnOnVideo") {
        setStudentStreamUser({
          type: "setState",
          data: {
            name: data.name,
            videoStatus: true,
            audioStatus: data.audioStatus == "on" ? true : false,
          },
        });
        setStudentStreamVideoStatus(true);
      } else if (data.type === "studentTurnOffVideo") {
        setStudentStreamUser({
          type: "setState",
          data: {
            name: data.name,
            videoStatus: false,
            audioStatus: data.audioStatus == "on" ? true : false,
          },
        });
        setStudentStreamVideoStatus(false);
      } else if (data.type === "studentTurnOnAudio") {
        setStudentStreamUser({
          type: "setState",
          data: {
            name: data.name,
            videoStatus: data.videoStatus == "off" ? false : true,
            audioStatus: true,
          },
        });
        setStudentStreamAudioStatus(true);
      } else if (data.type === "studentTurnOffAudio") {
        setStudentStreamUser({
          type: "setState",
          data: {
            name: data.name,
            videoStatus: data.videoStatus == "off" ? false : true,
            audioStatus: false,
          },
        });
        setStudentStreamAudioStatus(false);
      }

      if (data.user != undefined) {
        if (data.withdraw == 1) {
          setClassMembers({ type: "questionUserRemove", data: data.user });
        } else {
          presenceChannel.trigger("client-AskQuestion-" + classId, {
            type: "requestReceived",
            userId: parseInt(data.user.id),
          });

          setClassMembers({
            type: "questionUser",
            data: data.user,
            icon: data.type,
          });
          // setQuestionuser(data);
          // $('#exampleModal1').modal('show')

          setShowClasses(false);
        }
      }

      if (data.type == "studentHolderStreamStatus") {
        setTimeout(() => {
          setClassMembers({ type: "MEMBER_IN_PROGRESS_STOP", id: data.userId });
        }, 5000);
        if (data.status == "streamStarted") {
          if (data.initialized) {
            $(".PublisherHolderPlayerLoader" + data.userId).css(
              "display",
              "initial"
            );
          }
          setClassMembers({
            type: "memberStreamStatus",
            data: {
              id: data.userId,
              status: 1,
              audio: data.audio,
              video: data.video,
            },
          });
        } else {
          setClassMembers({
            type: "memberStreamStatus",
            data: { id: data.userId, status: 0, audio: false, video: false },
          });
          $(".PublisherHolderPlayerLoader" + data.userId).css(
            "display",
            "initial"
          );
          setTimeout(() => {
            $(".PublisherHolderPlayerLoader" + data.userId).css(
              "display",
              "none"
            );
          }, 3000);
        }
      }
    });

    presenceChannel.bind(
      "client-AskQuestion-request" + classId,
      async (data, metadata) => {
        if (data.type == "requestAccept") {
          await deleteExistingBroadcast();
          let streamId = "studentStream-" + data.userId + classId;
          presenceChannel.trigger("client-AskQuestion-" + classId, {
            type: "allowStudentStream",
            userId: data.userId,
            audio: data.audio,
            video: data.video,
            name: data.name,
            streamId: streamId,
            published: data.published,
          });
          $(".onBoardHolderLoader").css("display", "initial");
          setClassMembers({
            type: "setOnbaordUser",
            id: data.userId,
            status: true,
            classId: classId,
            channel: presenceChannel,
          });
          setStudentStreamUser({
            type: "setState",
            data: {
              name: data.name,
              videoStatus: data.video,
              audioStatus: data.audio,
            },
          });

          //handle refered to setStudentStreamStatus
          setStudentStreamStatus(true);

          if (handleOnAllow) {
            setStudentStreamId(streamId);
          }
        } else if (data.type == "toggleVideo") {
          setStudentStreamUser({
            type: "setState",
            data: {
              name: data.name,
              videoStatus: data.status,
              audioStatus: data.audio,
            },
          });
        } else {
          setTimeout(() => {
            setClassMembers({
              type: "MEMBER_IN_PROGRESS_STOP",
              id: data.userId,
            });
          }, 5000);

          toast.error(data.name + " rejected the request", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      }
    );

    let deleteExistingBroadcast = async () => {
      setStudentStreamStatus(false);
      setClassMembers({ type: "setActiveStreamUserOffline" });

      // if(studentStreamId !== ""){
      // 	console.log("stopPublished",studentStreamId);
      // 	await Axios.delete(env.baseUrl+"/remove/stream/"+studentStreamId).then(res => console.log(res));

      // }
    };

    const channel = pusher.subscribe("chatGroup" + classId);

    const authChannel = pusher.subscribe("AuthUser" + auth.userId());

    authChannel.bind("Logout", function () {
      window.sessionStorage.clear();

      if (classId !== undefined) {
        window.location.replace("/" + classId);
      } else {
        window.location.replace("/");
      }
    });

    setChannel(presenceChannel);

    Axios.get(
      env.baseUrl + "/class/messages/" + classId + "?page=" + messagePage,
      {
        headers: {
          Authorization: "Bearer " + auth.authToken(),
          "content-type": "multipart/form-data",
        },
      }
    ).then(({ data }) => {
      if (data.total_messages > data.messages.length) {
        setDisplayPrevMessageBtn(true);
      }
      setMessagePage(data.page);
      setMessages(data.messages);
      setTimeout(() => {
        scrollToBottom();
      }, 20);
    });

    let playSound = () => {
      let url = require("../../assets/message.mp3");
      let audio = new Audio(url);
      audio.play();
    };

    channel.bind("NewGroupMessage", function (data) {
      console.log("newGroupMessage", data);
      if (data.data.userId !== auth.userId()) {
        if (
          data.data.visible_only == null ||
          data.data.visible_only == auth.userId()
        ) {
          data.data.date = today;
          setNm(data.data);
          playSound();
          setTimeout(() => {
            scrollToBottom();
          }, 50);
          let element = document.getElementsByClassName("at-chathead");
          if (element[0] && element[0].clientWidth < 100) {
            setDisplayChatDot(true);
          }

          // console.log(fullScreen);

          // setDotIfFullScreenOn();
        }
      }
    });

    let today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    setDateToday(today);
  }, []);

  const callBackForStreamMemberAdded = (state) => {
    console.log(channel.members);
    console.log("state", state);
  };
  const setDotIfFullScreenOn = () => {
    if (fullScreen) {
      setDisplayChatDot(true);
    }
  };

  useEffect(() => {
    if (typeof nm.userId !== "undefined") {
      setMessages(messages.concat(nm));
    }
    setDotIfFullScreenOn();
  }, [nm]);

  let showHideChat = (e) => {
    e.preventDefault();
    setDisplayChatDot(false);
    if ($(".at-lacturechatscreen").hasClass("at-closemessagesidebar")) {
    }

    $(".at-lacturechatscreen").toggleClass("at-closemessagesidebar");
  };

  let fetchPreviousMessages = (e) => {
    e.preventDefault();
    setDisplayMessageLoader(true);
    setDisplayPrevMessageBtn(false);
    Axios.get(
      env.baseUrl + "/class/messages/" + classId + "?page=" + messagePage,
      {
        headers: {
          Authorization: "Bearer " + auth.authToken(),
          "content-type": "multipart/form-data",
        },
      }
    ).then(({ data }) => {
      setDisplayMessageLoader(false);
      let oldMessages = [...messages];
      if (data.messages.length > 0) {
        data.messages.map((message) => {
          oldMessages.unshift(message);
        });
        setMessagePage(data.page);
        setMessages(oldMessages);
        setDisplayPrevMessageBtn(true);
      } else {
        setDisplayPrevMessageBtn(false);
      }
    });
  };

  let startPublish = () => {
    if (streamStarted) {
      confirmAlert({
        title: languageContext.dictionary["sureToStopLesson"],
        message: languageContext.dictionary["youCanAlwaysResume"],
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              endClassPublish();
              endStream();
            },
          },
          {
            label: "No",
            onClick: () => {
              return false;
            },
          },
        ],
      });
    } else {
      if (tour) {
        setTour(false);
      }
      let res = webrtc.publish(classId);
      if (initialStreamStart) {
        setInitialStreamStart(false);
      } else {
        channel.trigger("client-AskQuestion-" + classId, {
          type: "classStreamRestart",
        });
      }

      if (res !== 0) {
        setStreamStarted(true);
        let toastID = toast.success(languageContext.dictionary["streamStarted"], {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  let endClassPublish = () => {
    webrtc.stop(classId);
    toast.success(languageContext.dictionary["streamEnded"], {
      position: toast.POSITION.TOP_CENTER,
    });
    setStreamStarted(false);
    setStudentStreamStatus(false);
    Axios.delete(env.baseUrl + "/remove/stream/" + classId).then((res) =>
      console.log(res)
    );
    channel.trigger("client-AskQuestion-" + classId, { type: "classEnded" });
  };

  let startRecording = () => {
    setRecordingStarted(true);
    Axios.get(env.baseUrl + "/start/recording/" + classId).then((res) =>
      console.log(res)
    );
    channel.trigger("client-AskQuestion-" + classId, {
      type: "RecordingStrated",
    });
  };

  let toggleRecording = () => {
    if (recordingStarted) {
      setRecordingStarted(false);
      Axios.get(env.baseUrl + "/stop/recording/" + classId).then((res) =>
        console.log(res)
      );
      channel.trigger("client-AskQuestion-" + classId, {
        type: "RecordingStopped",
      });
    } else {
      confirmAlert({
        title: "Start Recording",
        message: languageContext.dictionary["recordingStartedAlert"],
        buttons: [
          {
            label: languageContext.dictionary["startRecording"],
            onClick: () => {
              startRecording();
            },
          },
          {
            label: languageContext.dictionary["cancel"],
            onClick: () => {
              return false;
            },
          },
        ],
      });
    }
  };

  let switchVideo = () => {
    var mediaConstraints = {
      video: true,
      audio: true,
    };
    webrtc.switchVideoCameraCapture(classId, mediaConstraints);
  };

  let switchScreenShare = () => {
    webrtc.switchDesktopCaptureWithCamera(classId, "screen");
  };

  let screenShareCallBack = (message) => {
    console.log("Screen Share message", message);
    if (message == "allowed") {
      setScreenSharing(true);
    } else {
      setScreenSharing(false);
    }
    // if(message == 'stopped' || message == 'not allowed' ){
    //   setScreenSharing(false);
    // }
  };

  let videoToggleWithCameraCallBack = (message) => {
    if (message == "allowed") {
      setVideoStatus("on");
    }
  };

  let videoToggleWithoutCameraCallBack = (message) => {
    if (message == "allowed") {
      setVideoStatus("off");
    }
  };

  let toggleScreenSharing = (e, userInfo) => {
    // if (videoStatus == 'off') {
    //   alert("Please turn on your video");
    //   return;
    // }
    // checkAudioPermissions().then((hasPermission) => {
    //   if (hasPermission == false) {
    //     alert('Audio permission is required for screen sharing with audio.');
    //   }
    // });
    let trigger = {
      type: 'teacherStreamToggle',
      userInfo: userInfo,
      videoStatus: screenSharing == 'on' ? true : false,
    };
    if (!screenSharing) {
      console.log('videoStatus: ', videoStatus)
      if (videoStatus == "on") {
        trigger.status = 'turnOnScreenSharingWithVideo';
        webrtc.switchDesktopCaptureWithCamera(classId, screenShareCallBack);
      } else {
        trigger.status = 'turnOnScreenSharingWithoutVideo';
        webrtc.switchDesktopCapture(classId, screenShareCallBack);
      }
    } else {
      setVideoStatus("on");
      setScreenSharing(false);
      switchVideo();
    }
    console.log("Screen Sharing audioStatus" + audioStatus);
    console.log("Screen Sharing webrtc" + webrtc);
    if (audioStatus && (audioStatus == true || audioStatus == 'on')) {
      console.log("Screen Sharing audioStatus inside condition" + audioStatus);
      try {
        setTimeout(() => {
          webrtc.unmuteLocalMic();
        }, 2000);
      } catch (error) {
        console.error('Error unmuting microphone immediately:', error);
      }
    }
  };

  let toggleVideo = (e, userInfo) => {
    e.preventDefault();
    let trigger = {
      type: 'teacherStreamToggle',
      userInfo: userInfo,
      videoStatus: videoStatus == 'on' ? true : false,
    };
    if (screenSharing) {
      if (videoStatus === "on") {
        trigger.status = 'turnOffVideo'
        trigger.videoStatus = false;
        webrtc.switchDesktopCapture(classId, videoToggleWithoutCameraCallBack);
      } else {
        trigger.status = 'turnOnVideo'
        trigger.videoStatus = true;
        webrtc.turnOnLocalCamera();
        webrtc.switchDesktopCaptureWithCamera(
          classId,
          videoToggleWithCameraCallBack
        );
      }
    } else {
      if (videoStatus === "on") {
        setVideoStatus("off");
        trigger.status = 'turnOffVideo'
        trigger.videoStatus = false;
        webrtc.turnOffLocalCamera();
        console.log("websrtc : ", webrtc);
        toast.success(languageContext.dictionary["cameraTurnOff"], {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        setVideoStatus("on");
        trigger.status = 'turnOnVideo'
        trigger.videoStatus = true;
        webrtc.turnOnLocalCamera();
        toast.success(languageContext.dictionary["cameraTurnOn"], {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
    channel.trigger("client-AskQuestion-" + classId, trigger)
  };

  let gloalToggleMic = (e) => {
    if (globalMicStatus) {
      setGlobalMicStatus(false);
      channel.trigger("client-AskQuestion-" + classId, {
        type: "globalMicOff",
      });
    } else {
      setGlobalMicStatus(true);
      channel.trigger("client-AskQuestion-" + classId, {
        type: "globalMicOn",
      });
    }
  };

  let toggleAudio = (e) => {
    e.preventDefault();
    if (audioStatus === "on") {
      setAudioStatus("off");
      webrtc.muteLocalMic();
      toast.success(languageContext.dictionary["micTurnOff"], {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setAudioStatus("on");
      webrtc.unmuteLocalMic();
      toast.success(languageContext.dictionary["micTurnOn"], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  let scrollToBottom = () => {
    chatAreaEl = document.getElementById("chatArea");
    chatAreaEl.scrollTop = chatAreaEl.scrollHeight;
    console.log("scroll height", chatAreaEl.scrollHeight);
  };

  // Check WebRTC permissions before initiating screen sharing
  function checkAudioPermissions() {
    return navigator.mediaDevices.getUserMedia({ audio: true })
      .then(() => {
        console.log('Audio permission granted');
        return true;
      })
      .catch((error) => {
        console.error('Audio permission denied:', error);
        return false;
      });
  }
  function handleMessageSubmit(e) {
    e.preventDefault();
    console.log(dateToday);

    if (message == '' || message.trim() == '' || message == null || typeof (message) == undefined) {
      return;
    }
    let messageObj = {
      user: auth.username(),
      type: "text",
      userId: auth.userId(),
      self: 1,
      content: message,
      classId: classId,
      date: dateToday,
      is_teacher: 1,
    };

    if (selectedChatMember != null) {
      messageObj.visible_only = selectedChatMember.id;
      messageObj.visible_only_user = selectedChatMember.name;
    }

    setMessages(messages.concat(messageObj));
    setTimeout(scrollToBottom, 20);

    Axios.post(
      env.baseUrl + "/send/message",
      { messageObj },
      {
        headers: {
          Authorization: "Bearer " + auth.authToken(),
          "content-type": "multipart/form-data",
        },
      }
    ).then((data) => {
      console.log("data on message send", data);
    });

    setMessage("");
  }

  let uploadFile = (e) => {
    if (e.target.files.length <= 0) {
      return false;
    }
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("classId", classId);
    if (selectedChatMember != null) {
      formData.append("visible_only", selectedChatMember.id);
    }

    confirmAlert({
      title: languageContext.dictionary["confirmUpload"],
      message:
        languageContext.dictionary["sureToShare"] + e.target.files[0].name,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setUploading(true);
            Axios({
              method: "post",
              headers: {
                Authorization: "Bearer " + auth.authToken(),
                "content-type": "multipart/form-data",
              },
              data: formData,
              url: env.baseUrl + "/message/upload",
              onUploadProgress: (ev) => {
                const progress = (ev.loaded / ev.total) * 100;
                updateUploadProgress(Math.round(progress).toString());
              },
            }).then((resp) => {
              setUploading(false);

              setMessages(messages.concat(resp.data));
              setTimeout(() => {
                scrollToBottom();
              }, 20);
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            return false;
          },
        },
      ],
    });
  };

  let removeStudentStream = (e) => {
    e.preventDefault();
    let activeStreamStudentId = studentStreamId.replace("studentStream-", "");
    activeStreamStudentId = activeStreamStudentId.replace(classId, "");
    setClassMembers({
      type: "setOnbaordUser",
      id: activeStreamStudentId,
      status: false,
    });

    if (studentStreamId !== "") {
      console.log("stopPublished", studentStreamId);
      endStream();
    }
  };

  let endStream = () => {
    setStudentStreamStatus(false);
    setClassMembers({ type: "setActiveStreamUserOffline" });
    setStudentStreamId("");
    let activeStreamStudentId = studentStreamId.replace("studentStream-", "");
    activeStreamStudentId = activeStreamStudentId.replace(classId, "");

    channel.trigger("client-AskQuestion-" + classId, {
      type: "studentStreamRemoved",
      audio: true,
      video: true,
      userId: activeStreamStudentId,
    });
  };

  let toggleStudentVideo = (e) => {
    e.preventDefault();
    let type = "";
    if (studentStreamVideo) {
      type = "studentTurnOffVideo";
      setStudentStreamUser({
        type: "setState",
        data: {
          name: studentStreamUser.name,
          videoStatus: false,
          audioStatus: studentStreamUser.audioStatus,
        },
      });
      setStudentStreamVideoStatus(false);
    } else {
      setStudentStreamUser({
        type: "setState",
        data: {
          name: studentStreamUser.name,
          videoStatus: true,
          audioStatus: studentStreamUser.audioStatus,
        },
      });
      setStudentStreamVideoStatus(true);
      type = "studentTurnOnVideo";
    }

    let activeStreamStudentId = studentStreamId.replace("studentStream-", "");
    activeStreamStudentId = activeStreamStudentId.replace(classId, "");
    channel.trigger("client-AskQuestion-" + classId, {
      type: type,
      userId: parseInt(activeStreamStudentId),
    });
  };

  let toggleStudentAudio = (e) => {
    e.preventDefault();
    let type = "";
    if (studentStreamAudio) {
      type = "studentTurnOffAudio";
      setStudentStreamUser({
        type: "setState",
        data: {
          name: studentStreamUser.name,
          videoStatus: studentStreamUser.videoStatus,
          audioStatus: false,
        },
      });
      setStudentStreamAudioStatus(false);
    } else {
      setStudentStreamUser({
        type: "setState",
        data: {
          name: studentStreamUser.name,
          videoStatus: studentStreamUser.videoStatus,
          audioStatus: true,
        },
      });
      setStudentStreamAudioStatus(true);
      type = "studentTurnOnAudio";
    }

    let activeStreamStudentId = studentStreamId.replace("studentStream-", "");
    activeStreamStudentId = activeStreamStudentId.replace(classId, "");
    channel.trigger("client-AskQuestion-" + classId, {
      type: type,
      userId: parseInt(activeStreamStudentId),
    });
  };

  function handleOnAllow(status, streamId, userId) {
    let newState = [];
    setStudentStreamId(streamId);
    setStudentStreamStatus(status);
    classMembers.map((member) => {
      if (member.id === userId) {
        member.active = 1;
      } else {
        member.active = 0;
      }
      newState.push(member);
    });
    setClassMembers({ type: "setState", data: newState });
  }

  let turnOnFullScreen = () => {
    setSplitScreenClass("");
    setFullScreen({ type: "setFullScreen", data: 1 });
  };

  let turnOffFullScreen = (e) => {
    setDisplayChatDot(false);
    setFullScreenChat(false);
    setFullScreen({ type: "setFullScreen", data: 0 });
  };

  let showFullScreenChat = (e) => {
    e.preventDefault();
    console.log("clicked");
    if (fullScreenChat) {
      setFullScreenChat(false);
    } else {
      setFullScreenChat(true);
    }
  };

  useEffect(() => {
    if (
      studentStreamEnabled &&
      streamMemberRemove.id != undefined &&
      "studentStream-" + streamMemberRemove.id + classId == studentStreamId
    ) {
      endStream();
    }
  }, [streamMemberRemove]);

  let steps = [
    {
      selector: ".at-btnstop",
      content: languageContext.dictionary["startStream"],
    },
  ];

  return (
    <>
      <Tour
        steps={steps}
        isOpen={tour}
        onRequestClose={() => {
          setTour(false);
        }}
      ></Tour>
      <ClassRoomLoader
        style={loading ? { display: "block" } : { display: "none" }}
      />
      <div
        id="at-teacherlacturechatscreen"
        className={
          fullScreenChat
            ? "at-lacturechatscreen at-teacherlacturechatscreen at-chatfullscreen"
            : "at-lacturechatscreen at-teacherlacturechatscreen at-closemessagesidebar"
        }
        style={loading ? { display: "none" } : { display: "block" }}
      >
        <ToastContainer />
        <div id="at-sidebarchat" className="at-sidebarchat">
          <div className="at-logoarea">
            <strong className="at-logo short-logo">
              <a href="#">
                <img src={logo} alt="Logo Image" />
              </a>
            </strong>
          </div>
          <div className="at-chathead">
            <h2>
              <Text tid="chat" />
            </h2>
            <a
              id="at-btnhidshowchat"
              className="at-btnhidshowchat"
              href="#"
              onClick={(e) => showHideChat(e)}
            >
              <i className="icon-chat"></i>
              {displayChatDot ? <span className="dot-icon">.</span> : ""}
            </a>
          </div>
          {/* <div className="at-chaticonholder">
						<i className="icon-chat"></i>
					</div> */}
          <div
            className="at-chatmessagearea"
            id="chatArea"
            ref={(el) => (chatAreaEl = el)}
          >
            <div className="at-classmateschatarea">
              {displayPrevMessageBtn ? (
                <div className="at-messagetime">
                  <a href="/" onClick={(e) => fetchPreviousMessages(e)}>
                    <span>
                      <Text tid="loadPreviousMessage" />
                    </span>
                  </a>
                </div>
              ) : (
                ""
              )}
              {displayMessageLoader ? (
                <div style={{ textAlign: "center" }}>
                  <img
                    src={require("../../assets/loader.gif")}
                    style={{ width: "30px" }}
                  />
                </div>
              ) : (
                ""
              )}

              {messages.length > 0 ? (
                messages.map((message, i) => {
                  let devider = null;
                  if (
                    messages[i - 1] !== undefined &&
                    message.date !== messages[i - 1].date
                  ) {
                    if (message.date === dateToday) {
                      devider = "Today";
                    } else {
                      devider = message.date;
                    }
                  }
                  return (
                    <MessageHolder
                      key={i}
                      devider={devider}
                      message={message}
                      chatUser={chatUser}
                      isSameUser={
                        i !== 0 && messages[i - 1].userId === message.userId
                      }
                    />
                  );
                })
              ) : (
                <div className="at-themenodata">
                  <i className="icon-chat"></i>
                  <p>
                    <Text tid="itsNiceToStartCon" />
                  </p>
                  <h3>
                    <Text tid="letsStart" />
                  </h3>
                </div>
              )}
            </div>
          </div>
          <div className="at-chatfooter">
            {uploading ? (
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  style={{ width: uploadProgress + "%" }}
                  aria-valuenow={uploadProgress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            ) : (
              ""
            )}
            <form
              className="at-formtheme at-formwritemessage"
              onSubmit={(e) => handleMessageSubmit(e)}
            >
              <fieldset>
                <div className="form-group">
                  <span className="icon-text"></span>
                  <input
                    placeholder={
                      selectedChatMember != null
                        ? languageContext.dictionary["sentMessageVisible"] +
                        " " +
                        selectedChatMember.name
                        : languageContext.dictionary["typeMessage"]
                    }
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <ul className="at-bgchatattachopen">
                    <li>
                      <div className="at-themeattachfile">
                        <input
                          type="file"
                          name="select-file"
                          value=""
                          id="at-attachefile"
                          onChange={(e) => uploadFile(e)}
                        />
                        <label htmlFor="at-attachefile">
                          <i className="icon-attachment"></i>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="at-classmatesdropdown">
                        <div className="at-classmatesdropdownhead">
                          <h3>
                            <Text tid="classMates" />
                          </h3>
                          <a
                            id="at-closedrodown"
                            className="at-closedrodown"
                            href="#"
                          >
                            <i className="icon-cancel"></i>
                          </a>
                        </div>
                        <ClassMemberList
                          classMembers={classMembers}
                          setSelectedChatMember={setSelectedChatMember}
                          selectedChatMember={selectedChatMember}
                        />
                      </div>
                      <a
                        id="at-btnopenclassmatedropdown"
                        className="at-btnopenclassmatedropdown"
                        href="#"
                      >
                        <i className="icon-dots"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        <div className="at-lacturechatscreencontent at-lessonscreencontentholder">
          <header id="at-header" className="at-header">
            <ul className="at-lessonnametimedate">
              <li>
                <i className="icon-lesson-name"></i>
                <span>{virtualClass.title}</span>
              </li>
              {/* <li>
								<i className="icon-calendar"></i>
								<span>{virtualClass.date}</span>
							</li>
							<li>
								<i className="icon-time"></i>
								<span>{virtualClass.start_time} - {virtualClass.end_time}</span>
							</li> */}
            </ul>
            <div className="at-rightarea">
              <ProfileComponent endClassPublish={endClassPublish} />
              <LangSelector />
            </div>
          </header>
          <div
            className={`at-screencontentholder ${fullScreen && studentStreamEnabled ? splitScreenClass : ""
              } ${fullScreen ? "at-videofullscreen" : ""} ${studentStreamEnabled ? "studentStreamEnabled" : ""
              } ${showClasses ? "at-showClasses" : ""}`}
          >
            <div
              className={
                studentStreamEnabled
                  ? "at-videosholder at-studentStreamAdded"
                  : "at-videosholder"
              }
            >
              {/* <div className="at-videocamerascreenholder"> */}
              <figure className="at-classmatescreenimg teacherVideo">
                {/* <div className="rt-messagealert at-countanimation">{displayChatDot ? <span className="dot-icon">.</span> : '' }</div> */}

                {streamStarted ? (
                  <img
                    src={require("../../assets/live.gif")}
                    className="live"
                  />
                ) : (
                  ""
                )}
                <video id="localVideo" autoPlay muted playsInline />
                <div style={videoStatus == 'off' && !screenSharing ? { visibility: 'visible' } : { visibility: 'hidden' }}>
                  <Avatar src={require('../../assets/images/class-meates/img-01.png') + "s"} size="200px" style={{ "maxWidth": "240px", height: "180px" }} name={auth.username()} />
                </div>
                <ul className="at-btnvideoshare">
                  <li>
                    <button
                      className={`at-btnshare ${audioStatus == "off" ? " mic-mute" : "mic active"
                        }`}
                      onClick={(e) => toggleAudio(e)}
                    >
                      <i className="icon-mic"> </i>
                      {audioStatus == "off" ? (
                        <span className="at-themetooltip">
                          <Text tid="openYourMic" />
                        </span>
                      ) : (
                        <span className="at-themetooltip">
                          <Text tid="closeYourMic" />
                        </span>
                      )}
                    </button>
                  </li>
                  <li>
                    <button
                      className={`at-btnshare ${videoStatus == "on" ? "active" : ""
                        }`}
                      onClick={(e) => toggleVideo(e, auth.userInfo())}
                    >
                      <i
                        className={
                          videoStatus == "off"
                            ? "icon-video-cancel"
                            : "icon-video"
                        }
                      ></i>
                      {videoStatus == "off" ? (
                        <span className="at-themetooltip">
                          <Text tid="openYourVideo" />
                        </span>
                      ) : (
                        <span className="at-themetooltip">
                          <Text tid="closeYourVideo" />
                        </span>
                      )}
                    </button>
                  </li>
                  <li>
                    <button
                      className="at-btnshare at-btnfullscreen"
                      onClick={(e) => turnOnFullScreen()}
                    >
                      <i className="icon-add-picture"> </i>
                      <span className="at-themetooltip">
                        <Text tid="enlargeToFull" />
                      </span>
                    </button>
                    <button
                      className="at-btnexitfullscreen at-btnshare"
                      onClick={(e) => turnOffFullScreen(e)}
                    >
                      <i className="icon-cancel"></i>
                      <span className="at-themetooltip">
                        <Text tid="exitFull" />
                      </span>
                    </button>
                  </li>
                  <li>
                    <button
                      className={`at-btnshare at-btnsharescreen ${screenSharing ? "active" : ""
                        }`}
                      onClick={(e) => toggleScreenSharing(e, auth.userInfo())}
                    >
                      <i className="icon-display"></i>
                      <span className="at-themetooltip">
                        {screenSharing ? (
                          <Text tid="turnOffScreenSharing" />
                        ) : (
                          <Text tid="shareScreen" />
                        )}
                      </span>
                    </button>
                  </li>
                </ul>
              </figure>
              {/* </div> */}
              {studentStreamEnabled ? (
                <figure class="at-classmatescreenimg">
                  <div className="onBoardHolderLoader">
                    <span
                      style={{
                        width: "100px",
                        height: "100px",
                        background: "none",
                        top: "0",
                        left: "0",
                      }}
                      className="at-memberloadingicon"
                    >
                      <em>
                        <img
                          src={require("../../assets/images/loading-spin.gif")}
                        />
                      </em>
                    </span>
                  </div>
                  {/* <iframe key={resetIframe} width="50%" height="100%" src={"https://corsi.stream:5443/WebRTCAppEE/play_embed_student.html?name=" + studentStreamId} frameborder="0" allowFullScreen></iframe> */}
                  <AntPlayer
                    streamId={studentStreamId}
                    studentStreamUser={studentStreamUser}
                  />
                  <ul className="at-btnvideoshare">
                    <li>
                      <button
                        className="at-btnshare"
                        onClick={(e) => removeStudentStream(e)}
                      >
                        <i className="icon-negative"></i>
                        <span className="at-themetooltip">
                          <Text tid="removeStudent" />
                        </span>
                      </button>
                    </li>
                    <li>
                      <button
                        className={`at-btnshare ${studentStreamUser.videoStatus == true ? "active" : ""
                          }`}
                        onClick={(e) => toggleStudentVideo(e)}
                      >
                        <i
                          className={
                            studentStreamUser.videoStatus == true
                              ? "icon-video"
                              : "icon-video-cancel"
                          }
                        ></i>
                        {studentStreamUser.videoStatus == true ? (
                          <span className="at-themetooltip">
                            <Text tid="closeYourVideo" />
                          </span>
                        ) : (
                          <span className="at-themetooltip">
                            <Text tid="openYourVideo" />
                          </span>
                        )}
                      </button>
                    </li>

                    <li>
                      <button
                        className={`at-btnshare ${studentStreamUser.audioStatus == true
                          ? "mic active"
                          : " mic-mute"
                          }`}
                        onClick={(e) => toggleStudentAudio(e)}
                      >
                        <i className="icon-mic"> </i>
                        {studentStreamUser.audioStatus == true ? (
                          <span className="at-themetooltip">
                            <Text tid="closeYourMic" />
                          </span>
                        ) : (
                          <span className="at-themetooltip">
                            <Text tid="openYourMic" />
                          </span>
                        )}
                      </button>
                    </li>
                  </ul>
                </figure>
              ) : (
                ""
              )}
              <div className="at-fullscreenbuttons">
                {fullScreen ? (
                  <a
                    id="at-btnhidshowchat"
                    className="at-btnhidshowchat"
                    href="#"
                    style={{ zIndex: 9999 }}
                    onClick={(e) => showFullScreenChat(e)}
                  >
                    <span class="at-themetooltip">Click To See Messages</span>
                    <i className="icon-chat"></i>
                    {displayChatDot ? <span className="dot-icon">.</span> : ""}
                  </a>
                ) : (
                  ""
                )}
                <button
                  className="at-btnswitchview at-btnclasses"
                  onClick={(e) => turnOffFullScreen()}
                >
                  <span class="at-themetooltip">Click To See Classes</span>
                  Classes
                </button>
                <button
                  className="at-btnswitchview"
                  onClick={() => setSplitScreenClass("at-teacherview")}
                >
                  <img src={teacherIcon} alt="Teacher Icon" />
                  <span class="at-themetooltip">Click To Teacher View</span>
                </button>
                {studentStreamEnabled ? (
                  <>
                    <button
                      className="at-btnswitchview"
                      onClick={() =>
                        setSplitScreenClass("at-teacherstudentview")
                      }
                    >
                      <span class="at-themetooltip">
                        Click To Teacher Student View
                      </span>
                      <img src={teacherIcon} alt="Teacher Icon" />
                      <i className="icon-user"></i>
                    </button>
                    <button
                      className="at-btnswitchview"
                      onClick={() => setSplitScreenClass("at-studentview")}
                    >
                      <span class="at-themetooltip">
                        Click To Student View.
                      </span>
                      <i className="icon-user"></i>
                    </button>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              className={`at-classattandantholder ${splitView ? "split-view-class" : ""
                }`}
            >
              <span className="at-classesheading">Classes</span>
              <button
                className="at-btnhideclasses"
                onClick={() => setShowClasses(!showClasses)}
              >
                <i className="icon-embed2"></i>
              </button>
              <div className="at-sectiontitle">
                <h3>
                  <Text tid="class" />
                  <button
                    className="at-btnclassfullscreen"
                    onClick={() => setSplitView(!splitView)}
                  >
                    <img
                      className="at-normalscreenimg"
                      src={require("../../assets/images/zoom-class-icon.png")}
                      style={{ maxWidth: "40px" }}
                    />
                    <img
                      className="at-fullscreenimg"
                      src={require("../../assets/images/zoom-class-icon2.png")}
                      style={{ maxWidth: "40px" }}
                    />
                  </button>

                  <button
                    className="at-btnclassfullscreen"
                    onClick={gloalToggleMic}
                  >
                    {globalMicStatus ? (
                      <span className="at-themetooltip">
                        <Text tid="closeTheMicForALlStudents" />
                      </span>
                    ) : (
                      <span className="at-themetooltip">
                        <Text tid="openTheMicForAllStudents" />
                      </span>
                    )}
                    <img
                      className="at-normalscreenimg"
                      src={
                        globalMicStatus
                          ? teacherMicOnIcon
                          : teacherMicOffIcon
                      }
                      style={{ width: "35px" }}
                    />
                  </button>
                </h3>
                <span className="at-onlinemates">
                  <Text tid="online" /> <strong>{onlineMembers}</strong>/
                  {classMembers.length}
                </span>
              </div>
              <div className="at-onlineclassmatesholder">
                {classMembers.map((member, i) => {
                  return (
                    <ClassMemberHolder
                      key={i}
                      member={member}
                      classId={classId}
                      handle={setStudentStreamStatus}
                      setClassMembers={setClassMembers}
                      onAllowAudio={handleOnAllow}
                      globalMicStatus={globalMicStatus}
                      existingStreamId={studentStreamId}
                      setQuestionuser={setQuestionuser}
                      studentStreamEnabled={studentStreamEnabled}
                      studentStreamId={studentStreamId}
                      presenceChannel={channel}
                    />
                  );
                })}
              </div>
            </div>
            <div className="at-callbuttonstimer at-recordingcalltimer">
              {/* <div className="at-recordbuttonholder">
								<a className="at-btnrecord at-recordingstart" href="#"><span></span></a>
							</div> */}
              <div className="at-btnsholder">
                {streamStarted ? (
                  <a
                    className="at-btnthemevideo at-btnstop at-btnstartrecording"
                    href="#"
                    onClick={() => toggleRecording()}
                    data-toggle="modal"
                    data-target="#exampleModal2"
                  >
                    <span>
                      <i
                        className={
                          recordingStarted ? "icon-stop-button" : "icon-play3"
                        }
                      >
                        <em>Rec</em>
                      </i>
                    </span>
                  </a>
                ) : (
                  ""
                )}

                <a
                  className="at-btnthemevideo at-btnstop"
                  href="#"
                  onClick={() => startPublish()}
                  data-toggle="modal"
                  data-target="#exampleModal2"
                >
                  <span>
                    <i
                      className={
                        streamStarted ? "icon-stop-button" : "icon-play3"
                      }
                    ></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <PermissionModal
          channel={channel}
          user={questionUser}
          classId={classId}
          handle={setStudentStreamStatus}
          onAllowAudio={handleOnAllow}
          setClassMembers={setClassMembers}
          existingStreamId={studentStreamId}
          setStudentStreamUser={setStudentStreamUser}
          setStudentStreamVideoStatus={setStudentStreamVideoStatus}
        />
      </div>
    </>
  );
};

export default ClassRoom;
