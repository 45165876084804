import React, {useContext} from 'react';
import {LanguageContext} from '../Hooks/MultiLanguage/MultiLangContext'

const Text = ({tid}) => {    
    const languageContext = useContext(LanguageContext);
  
    return languageContext.dictionary[tid]; 
};

export default Text;


