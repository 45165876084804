
class Env {
    baseUrl = 'https://corsi.stream/administrative/public/api';
    appUrl = 'https://corsi.stream/';
    maxLimit = 5;
    PUSHER_APP_KEY = 'a6c4e63a84dcbf6043a9';
    PUSHER_APP_CLUSTER = 'ap2';
}

const env = new Env();

export default env;
