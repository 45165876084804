import React from 'react';
import ReactTooltip from 'react-tooltip';
import Text from '../../lang/Text'
import Avatar from 'react-avatar'
import auth from '../../Auth/authinfo';

const PermissionModal = ({presenseChannel,classId,setAudioStatus,setStudentStreamStatus,setVideoStatus,memberStreamStatus}) => {

	let allowAudio = () => {

		setAudioStatus('on');
		// setStudentStreamStatus({ type: 'studentTurnOnAudio' })
		// setStudentStreamStatus({ type: 'studentTurnOffVideo' })		
		setVideoStatus('off')
		presenseChannel.trigger("client-AskQuestion-request" + classId,{
			type: 'requestAccept',
			userId : auth.userId(),
			name : auth.username(),
			audio : true,
			video : false,
			published: memberStreamStatus.published 
        })
	}


	let allowVideo = () => {
		setAudioStatus('on');
		// setStudentStreamStatus({ type: 'studentTurnOnAudio' })
		// setStudentStreamStatus({ type: 'studentTurnOnVideo' })		
		setVideoStatus('on')
		
		presenseChannel.trigger("client-AskQuestion-request" + classId,{
			type: 'requestAccept',
			userId : auth.userId(),
			name : auth.username(),
			audio : true,
			video : true,
			published: memberStreamStatus.published 
        })
	}


	let rejectRequest = () => {
		console.log();
		
		presenseChannel.trigger("client-AskQuestion-request" + classId,{
			type: 'reject',
			userId : auth.userId(),
			name : auth.username(),
			audio : true,
			video : true,
        })
	}

    return (
        <div className="at-thememodal modal at-modalpermission fade" id="exampleModal2" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
		<div className="modal-dialog at-modaldialog" role="document">
			<div className="modal-content at-modalcontent">
				<div className="modal-header at-modalheader">
					<h5 className="modal-title at-modaltitle" id="exampleModalLabel1"><Text tid="permission" /></h5>
					<button type="button" data-tip="Close" className="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div className="modal-body at-modalbody">
					<div className="at-permissionontent">
						<figure className="at-permissionimg">
						<Avatar src={require('../../assets/images/class-meates/img-01.png')+"s"} size="200px" style={{width : "130px", height : "130px"}} name="teacher" />
						</figure>
						<div className="at-description">
							<p><strong><Text tid="teacher" /> <span id="teacherName"> </span></strong><Text tid="wouldLikeToComeBoard" /></p>
						</div>
						<ul className="at-btnpermisions">
							<ReactTooltip place="top" type="dark" effect="float"/>
							<li>
								<span><Text tid="acceptAndStartVideo" /></span>
								<a data-tip="Allow Audio and Video" className="at-btnpermision" onClick={() => {allowVideo()}} data-dismiss="modal"><i className="icon-video"></i></a>
							</li>
							<li>
								<span>Start With Audio Only</span>
								<a data-tip="Allow Audio Only" className="at-btnpermision" onClick={() => {allowAudio()}} data-dismiss="modal"><i className="icon-sound"></i></a>
							</li>
							<li>
								<span><Text tid="refuse" /></span>
								<a  onClick={() => {rejectRequest()}} data-tip="Deny Permission" className="close" data-dismiss="modal" className="at-btnpermision" ><i className="icon-cancel"></i></a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
    );
};

export default PermissionModal;