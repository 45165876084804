import React from 'react';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import Text from '../../lang/Text'
import Axios from 'axios'
import env from '../../env'
import Avatar from 'react-avatar'

const PermissionModal = ({user,channel,classId,handle, onAllowAudio, setClassMembers,existingStreamId,setStudentStreamVideoStatus,setStudentStreamUser}) => {

	let allowAudio = async (e) => {
		e.preventDefault();
		await deleteExistingBroadcast()
		setClassMembers({ type: 'questionUserRemove', data: user})
		setClassMembers({type : 'setOnbaordUser', id : user.id, status: true})
		setStudentStreamUser({ type: 'setState', data: {name: user.name, videoStatus : true, audioStatus : true} })
		let streamId = 'studentStream-'+user.id+classId
		channel.trigger("client-AskQuestion-" + classId,{
			type: 'allowStudentStream',
			userId : user.id,
			audio : true,
			video : false,
			streamId : streamId
		})
		handle(true)
		$('#exampleModal1').modal('hide');

		if (onAllowAudio) {
			onAllowAudio(true,streamId,user.id);
		}
		setStudentStreamVideoStatus(false)
		
	}

	let allowVideo = async (e) => {
		await deleteExistingBroadcast()
		e.preventDefault();
		setClassMembers({ type: 'questionUserRemove', data: user})

		setClassMembers({type : 'setOnbaordUser', id : user.id, status: true})
		setStudentStreamUser({ type: 'setState', data: {name: user.name, videoStatus : true, audioStatus : true} })

		let streamId = 'studentStream-'+user.id+classId
		channel.trigger("client-AskQuestion-" + classId,{
			type: 'allowStudentStream',
			userId : user.id,
			audio : true,
			video : true,
			streamId : streamId
		})
		handle(true)
		$('#exampleModal1').modal('hide');

		if (onAllowAudio) {
			onAllowAudio(true,streamId,user.id);
		}
		setStudentStreamVideoStatus(true)
	}

	let permissionDenied = () => {
		setClassMembers({ type: 'questionUserRemove', data: user})

		channel.trigger("client-AskQuestion-" + classId,{
			type: 'permissionDenied',
			userId : user.id,
			audio : true,
			video : true
		})
	} 

	let deleteExistingBroadcast = async () => {
		if(existingStreamId !== ""){
			console.log("stopPublished",existingStreamId);
			await Axios.delete(env.baseUrl+"/remove/stream/"+existingStreamId).then(res => console.log(res));
			
		}
	}

    return (
        <div className="at-thememodal modal at-modalpermission fade" id="exampleModal1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
		<div className="modal-dialog at-modaldialog" role="document">
			<div className="modal-content at-modalcontent">
				<div className="modal-header at-modalheader">
					<h5 className="modal-title at-modaltitle" id="exampleModalLabel1"><Text tid="permission" /></h5>
					<button type="button" data-tip="Close" className="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div className="modal-body at-modalbody">
					<div className="at-permissionontent">
						<figure className="at-permissionimg">
						<Avatar src={require('../../assets/images/class-meates/img-01.png')+"s"} size="200px" style={{width : "130px", height : "130px"}} name={user.name} />
						</figure>
						<div className="at-description">
							<p><strong>{user.name} </strong><Text tid={user.askQuestionIcon == 'icon-info-1' ? "wantToAnswerQuestion" : "wantToAskQuestion" } /></p>
						</div>
						<ul className="at-btnpermisions">
							<ReactTooltip place="top" type="dark" effect="float"/>
							<li>
								<span><Text tid="openTheStudentVideo" /></span>
								<a data-tip="Allow Audio and Video" className="at-btnpermision" onClick={e => allowVideo(e)}><i className="icon-video"></i></a>
							</li>
							<li>
								<span><Text tid="openTheStudentAudio" /></span>
								<a data-tip="Allow Audio Only" className="at-btnpermision" onClick={e => allowAudio(e)}><i className="icon-sound"></i></a>
							</li>
							<li>
								<span><Text tid="rejectTheRequest" /></span>
								<a onClick={() => {permissionDenied()}} data-tip="Deny Permission" className="close" data-dismiss="modal" className="at-btnpermision" ><i className="icon-cancel"></i></a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
    );
};

export default PermissionModal;