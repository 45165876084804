import React,{useEffect} from 'react';
import WebRTCAdaptor from '../../Webrtc/webrtc_adaptor';
import $ from 'jquery'
import Avatar from 'react-avatar'


const MemberHolderAntPlayer = ({streamId,member,setClassMembers,muted,setLoading}) => {
    useEffect(() => {
        var token = "null";
    
        function playVideo() {

            try {
             
           document.getElementById("video-"+streamId).play().then(function(value){
            //   alert('play started'+streamId)
           }).catch(function(error) {
                //
                // document.getElementById("play_button").style.display="block";
                console.log("User interaction needed to start playing");
           });   
            } catch (error) {
              console.log(error);
                
            }
    
        }
        var pc_config = null;
    
        var sdpConstraints = {
            OfferToReceiveAudio : true,
            OfferToReceiveVideo : true
    
        };
        var mediaConstraints = {
            video : false,
            audio : false
        };
        
        let websocketURL = "wss://" + 'corsi.stream:5443/WebRTCAppEE/websocket';

        var webRTCAdaptor = new WebRTCAdaptor({
            websocket_url : websocketURL,
            mediaConstraints : mediaConstraints,
            peerconnection_config : pc_config,
            sdp_constraints : sdpConstraints,
            remoteVideoId : 'video-'+streamId,
            isPlayMode: true,
            debug: true,
            callback : function(info, description) {
                
                if (info == "initialized") {
                    webRTCAdaptor.getStreamInfo(streamId);
                }
                else if (info == "streamInformation") {
                    webRTCAdaptor.play(streamId, token);
                }
                else if (info == "play_started") {
                    if(member != undefined){
                        setClassMembers({ type: 'memberStreamStatus', data: {id : member.id, status:1,audio:undefined,video:undefined} });
                        setClassMembers({ type: 'MEMBER_IN_PROGRESS_STOP', id: member.id })
                        $('.PublisherHolderPlayerLoader'+member.id).css('display','none')

                    }
                    playVideo();


                } else if (info == "play_finished") {
                    if(member != undefined){
                        setClassMembers({ type: 'memberStreamStatus', data: {id : member.id, status:0 ,audio:undefined,video:undefined} });
                    }
                    setTimeout(function(){
                        webRTCAdaptor.getStreamInfo(streamId);
                    }, 3000);
                }
                else if (info == "closed") {
                    if (typeof description != "undefined") {
                    }
                }
    
            },
            callbackError : function(error) {

                
                if (error == "no_stream_exist") {
                    setTimeout(function(){
                        webRTCAdaptor.getStreamInfo(streamId);
                    }, 3000);
                }
            }
        });
    },[streamId])
    return (
        <>
        <div style={member.streamingStatus == 1 ? {display : 'initial'} : {display : 'none'}} >
            <video id={"video-"+streamId} muted={muted ? true : false}/>
        </div>

        <div style={member.streamingStatus != 1 ? {display : 'initial'} : {display : 'none'}} >
            <Avatar src={require('../../assets/images/class-meates/img-01.png')+"s"} size="200px" style={{"maxWidth" : "240px", height : "180px"}} name={member.name} />
        </div>
        </>
    );
};

export default MemberHolderAntPlayer;