
import $ from 'jquery';

export const jqueryThemeFunc = () => {    
    
    /* -------------------------------------
    		OPEN CLOSE LANGUAGES
    -------------------------------------- */
    var removeClass = true;
    // $('#at-btnopenlanguage').on('click', function(event) {
    //     event.preventDefault();
    //     $('.at-selectlangauge').toggleClass('at-openlanguagedropdown');
    //     removeClass = false;
    //     $('.at-langauges').slideToggle();
    // });
    // $('.at-langauges').on('click', function(){
    //     removeClass = false;
    // });
    var removeClass = true;
    if($('#at-btnfilter').length > 0){
        $('#at-btnfilter').on('click', function(){
            $('.at-filterbtnholder').toggleClass('at-openfilters');
            removeClass = false;
        });
    };
    $('.at-filters').on('click', function(){
        removeClass = false;
    });
    if($('#at-btnclosefilter').length > 0){
        $('#at-btnclosefilter').on('click', function(){
            $('.at-filterbtnholder').toggleClass('at-openfilters');
        });
    };
    $("html").click(function () {
        if (removeClass) {
            $(".at-filterbtnholder").removeClass('at-openfilters');
        }
        removeClass = true;
    });
    /* -------------------------------------
    		OPEN CLOSE CLASSMATES
    -------------------------------------- */
    $('#at-btnopenclassmatedropdown, .at-themenodata h3').on('click', function(event) {
        event.preventDefault();
        $('.at-bgchatattachopen').addClass('at-openmatedropdown');
        removeClass = false;
    });
    $('#at-closedrodown').on('click', function(event) {
        event.preventDefault();
        $('.at-bgchatattachopen').removeClass('at-openmatedropdown');
        removeClass = false;
    });
    $('.at-btnfullscreen').on('click', function(event) {
        event.preventDefault();
        $('.at-screencontentholder').addClass('at-videofullscreen');
    });
    $('.at-btnexitfullscreen').on('click', function(event) {
        event.preventDefault();
        $('.at-screencontentholder').removeClass('at-videofullscreen');
    });
    /* -------------------------------------
    	OPEN CLOSE SIDEBAR CHAT MESSAGES
    -------------------------------------- */
    
}

// $(document).ready(jqueryThemeFunc);
