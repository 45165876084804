const en = {
    main: 'This is English Text',
    chat : 'Chat',
    selectLang : 'English',
    class : 'class',
    classMates : 'Classmates',
    typeMessage : 'Type a message here',
    sorry : 'Sorry',
    linkNotAvailable : 'This Is Not A Valid Link!',
    openValidLessonlink : 'Please open a valid lesson link',
    welcomeTo : 'Online Courses Platform',
    weMakeEasy : 'We make it easy for everyone to learn online!',
    useYouraccount : 'Use your Portalecorsi account to log in:',
    password : 'Password',
    forgotPassword : 'www.portalecorsi.com',
    youforgotPassword : 'Did you forget your password? Request it on',
    signIn : 'Log In',
    dontHaveAccount : "Registration for courses on",
    signUp : 'www.portalecorsi.com',
    lesson : 'Lesson',
    willStartIn : 'will start in',
    minutes : 'Minutes',
    enterIntoLesson : "Enter the classroom",
    everyOne : 'Everyone',
    wantToAsk : 'want to ask something',
    permission : 'Permission',
    wantToAskQuestion : 'wants to ask a question',
    wantToAnswerQuestion : 'wants to answer to your question',
    online : "Online",
    
    
    requestGanted : "Request to ask question granted",
    requestReceived : "Request received by teacher",
    requestDenied : "Request to ask question denied",
    confirmUpload : "Confirm to Upload",
    sureToShare : "Are you sure to share",
    requestWithdrawn : "Request Withdrawn",
    loadPreviousMessage : "Load Previous Messages",
    streamEnded : "Stream Ended",
    withdrawnRequest : "Withdraw Request",
    askedQuestion : "Ask the teacher a question",
    answerQuestion : "Answer a question from the teacher",
    startStream : "Click the button to start streaming when you are ready",
    sentMessageVisible : "Sent Message will be only visible to",

    sureToStopLesson : "Are you sure you want to stop the lesson?",
    youCanAlwaysResume : "You can always resume",
    streamStarted : "Streaming Started",
    cameraTurnOff : "Camera Turned off",
    cameraTurnOn : "Camera Turned On",
    micTurnOff : "Mic Turned Off",
    micTurnOn : "Mic Turned On",
    itsNiceToStartCon : "It's nice to start conversation with someone!",
    letsStart : "Let's Start",


    openYourMic : "Your microphone is closed, to open the microphone (and let the class hear you) click this button",
    closeYourMic : "Close your microphone, the class will not hear you anymore.",
    openYourVideo : "Open your video, so the class can see you.",
    closeYourVideo: "Close your video, the class will not see you anymore.",
    enlargeToFull : "Enlarge to full screen.",
    exitFull : "Exit full screen.",
    shareScreen : "Share your screen",
    turnOffScreenSharing : "Turn off screen sharing",

    openCameraOfStudent :  "Bring this student to the board,the class will be able to see you both on the main screen.",

    openTheStudentVideo : "Open the student's video and audio",
    openTheStudentAudio : "Open only the student's audio",
    rejectTheRequest : "Reject the request",

    expellStudent : "Expel this student from the class",
    openStudentMicroPhone : "Open the microphone of this student, so all the class will hear his voice.",

    teacher: "The teacher",
    wouldLikeToComeBoard : "would like you to come to the board: the class will be able to see you both on the main screen.",
    acceptAndStartVideo : "Accept (open video and audio) ",
    refuse : "Refuse",

    waitText: "THE LESSON HAS NOT STARTED YET",
    zoomStudentvideo: "“Expand this student’s video on full screen (only vsible to you)",
    closeZoomStudentVideo : "Exit from full view",
    closeStudentvideo: "Turn off the video",
    removeStudent: "Close",
    studentStarupmodal: "You are about to enter the classroom. Wait for the teacher to start the lesson video. ",
    studentCameramic: "Your microphone and camera will initially be closed, if you want to open them, click those buttons of your box.",
    toOPenmicrophone: "To open your Microphone",
    toOpencamera: "To open your Camera",
    enterTheclass: "OK? ENTER THE CLASS!",
    allow : "Allow",
    wantToTurnOnAudio : "wants to turn on your audio",
    wantToTurnOnVideo : "wants to turn on your video",

    openVideo : "Open Video",
    closeVideo : "Close Video",
    openMic : "Open Microphone",
    closeMic : "Close Microphone",
    InterNetgone : "Your internet connection is slowing down",
    closeTheMicForALlStudents : "Close the microphone for all students. They won't be able to talk until you open it again.",
    openTheMicForAllStudents : "Open the microphone for all students. They can talk.",
    reconnectStream : "If you get delayed video / audio, click this button to catch up",
    PleaseEnterEmail : "Please enter your email:",
    SendPassword : "Send Password",
    EnterNewPassword : "Enter new password:",
    SubmitPassowrd : "Submit",
    useChromeBrowser : 'Please use this platform with Google Chrome',
    downloadChromeTitle : 'To use this platform, you need to use Google Chrome.',
    downloadChromeText : "Please open the page using Chrome.If you don't have Chrome, click here to install it now (Don't worry, it's very easy)",
    recordingStarted : "The teacher is starting the recording of the lesson. Note: the registration only involves the main screen, that is teacher and the student eventually called to the blackboard. In any case if you don't want to be recorded you can always keep your microphone and video closed.",
    gotIt : "GOT IT",
    cancel : "CANCEL",
    startRecording : "START RECORDING",
    recordingStartedAlert : "Note: the recording only involves the main screen (teacher and students called to the blackboard).",
    maxStreamReached : "At the moment your video cannot be played, too many videos open at the same time in the class will slow down your connection and your streaming experience. If you want to ask the teacher a question, use the Question button",

  };
  
  export default en;