import WebRTCAdaptor from './webrtc_adaptor';
import $ from 'jquery'


function webRTCAdaptorFunc() {

    let token = '';
    var streamId;

    function startPublishing() {
        streamId = 'stream1';
        webRTCAdaptor.publish(streamId, token);
    }

    function startAnimation() {

        $("#broadcastingInfo").fadeIn(800, function () {
            $("#broadcastingInfo").fadeOut(800, function () {
                var state = webRTCAdaptor.signallingState(streamId);
                if (state != null && state != "closed") {
                    var iceState = webRTCAdaptor.iceConnectionState(streamId);
                    if (iceState != null && iceState != "failed" && iceState != "disconnected") {
                        startAnimation();
                    }
                }
            });
        });

    }


    //====================================== NEW FUNCTION===========================////

    var pc_config = {
        'iceServers' : [ {
            'urls' : 'stun:stun1.l.google.com:19302'
        } ]
    };


var maxVideoBitrateKbps = 900;


var autoRepublishEnabled = true;
/**
 * Timer job that checks the WebRTC connection 
 */
var autoRepublishIntervalJob = null;

var sdpConstraints = {
    OfferToReceiveAudio : false,
    OfferToReceiveVideo : false
};

var mediaConstraints = {
    video : true,
    audio : true
};

 let websocketURL = "wss://" + 'corsi.stream:5443/WebRTCAppEE/websocket';


var	webRTCAdaptor = null;
var selectedRadio = null;
var selectedAudio = null;

webRTCAdaptor = new WebRTCAdaptor({
    websocket_url : websocketURL,
    mediaConstraints : mediaConstraints,
    peerconnection_config : pc_config,
    sdp_constraints : sdpConstraints,
    localVideoId : "localVideo",
    debug:true,
    bandwidth:maxVideoBitrateKbps,
    callback : (info, obj) => {
        if (info == "initialized") {
            console.log("initialized");
            
        } else if (info == "publish_started") {
            //stream is being published
            console.log("publish started");
            
            startAnimation();
            if (autoRepublishEnabled && autoRepublishIntervalJob == null) 
            {
                autoRepublishIntervalJob = setInterval(() => {
                    // checkAndRepublishIfRequired();
                }, 3000);
            }
            webRTCAdaptor.enableStats(obj.streamId);
            // enableAudioLevel();

        } else if (info == "publish_finished") {
            //stream is being finished
            console.log("publish finished");
        }
        else if (info == "browser_screen_share_supported") {
           
        }
        else if (info == "screen_share_stopped") {
            //choose the first video source. It may not be correct for all cases. 
           
            console.log("screen share stopped");
        }
        else if (info == "closed") {
            //console.log("Connection closed");
            if (typeof obj != "undefined") {
                console.log("Connecton closed: " + JSON.stringify(obj));
            }
        }
        else if (info == "pong") {
            //ping/pong message are sent to and received from server to make the connection alive all the time
            //It's especially useful when load balancer or firewalls close the websocket connection due to inactivity
        }
        else if (info == "refreshConnection") {
            // checkAndRepublishIfRequired();
        }
        else if (info == "ice_connection_state_changed") {
            console.log("iceConnectionState Changed: ",JSON.stringify(obj));
        }
        else if (info == "updated_stats") {
            //obj is the PeerStats which has fields
             //averageOutgoingBitrate - kbits/sec
            //currentOutgoingBitrate - kbits/sec
            console.log("Average outgoing bitrate " + obj.averageOutgoingBitrate + " kbits/sec"
                    + " Current outgoing bitrate: " + obj.currentOutgoingBitrate + " kbits/sec"
                    + " video source width: " + obj.resWidth + " video source height: " + obj.resHeight
                    + "frame width: " + obj.frameWidth + " frame height: " + obj.frameHeight
                    + " video packetLost: "  + obj.videoPacketsLost + " audio packetsLost: " + obj.audioPacketsLost
                    + " video RTT: " + obj.videoRoundTripTime + " audio RTT: " + obj.audioRoundTripTime 
                    + " video jitter: " + obj.videoJitter + " audio jitter: " + obj.audioJitter);

                    
            if (obj.averageOutgoingBitrate > 0)  {
            }
            else {
            }

            if (obj.currentOutgoingBitrate > 0) {
            }
            else {
            }
            var packetLost = parseInt(obj.videoPacketsLost) + parseInt(obj.audioPacketsLost);	
            
            if (packetLost > -1) {
            }
            else {
            }
            var jitter = ((parseFloat(obj.videoJitter) + parseInt(obj.audioJitter)) / 2).toPrecision(3);
            if (jitter > 0) {
            }
            else {
            }
        
            var rtt = ((parseFloat(obj.videoRoundTripTime) + parseFloat(obj.audioRoundTripTime)) / 2).toPrecision(3);
            if (rtt > 0) {
            }
            else {
            }
            
           if (obj.resWidth > 0 && obj.resHeight > 0) {
            }
            else {
            }

          
            if (obj.frameWidth > 0 && obj.frameHeight > 0) {
            }
            else {
            }
            
            if (obj.currentFPS > 0) {
            }
            else {
            }


        }
        else if (info == "data_received") {
            // console.log("Data received: " + obj.event.data + " type: " + obj.event.type + " for stream: " + obj.streamId);
        }
        else if (info == "available_devices") {
            var videoHtmlContent = "";
            var audioHtmlContent = "";
            var devices = new Array();
    
            var i = 0;
            obj.forEach(function(device) {
                var label = device.label;
                var deviceId = device.deviceId;
                var devices = new Array();

                devices.forEach(function(same){
                    if (same == device.label){
                        i += 1;
                        label = device.label + " - " + i
                        deviceId = device.deviceId + i
                    }
                })
                if (device.kind == "videoinput") {
                    // videoHtmlContent += getCameraRadioButton(label, device.deviceId);
                }
                else if (device.kind == "audioinput"){
                    // audioHtmlContent += getAudioRadioButton(label, device.deviceId);
                }
                devices.push(device.label)
            }); 
            $('[name="videoSource"]').remove();
            $('[name="audioDeviceSource"]').remove();

            // videoHtmlContent += getScreenButton();
            // videoHtmlContent += getScreenWithCamButton();

            $(videoHtmlContent).insertAfter(".video-source-legend");
            $(".video-source").first().prop("checked", true);	
            
            $(audioHtmlContent).insertAfter(".audio-source-legend");
            $(".audio-source").first().prop("checked", true);	

            
            if (document.querySelector('input[name="videoSource"]')) {
                document.querySelectorAll('input[name="videoSource"]').forEach((elem) => {
                    elem.addEventListener("change", function(event) {
                    var item = event.target;
                    // switchVideoMode(item)
                    selectedRadio = item.value;
                    });
                  });
            }
            if (document.querySelector('input[name="audioSource"]')) {
                document.querySelectorAll('input[name="audioSource"]').forEach((elem) => {
                    elem.addEventListener("change", function(event) {
                    var item = event.target;
                    // switchAudioMode(item)
                    selectedAudio = item.value;
                    });
                  });
            }
            $(":radio[value=" + selectedRadio + "]").prop("checked", true);
            $(":radio[value=" + selectedAudio + "]").prop("checked", true);
        }
        else {
            console.log( info + " notification received");
        }
    },
    callbackError : function(error, message) {
        //some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError

        console.log("error callback: " +  JSON.stringify(error));
        var errorMessage = JSON.stringify(error);
        if (typeof message != "undefined") {
            errorMessage = message;
        }
        var errorMessage = JSON.stringify(error);
        if (error.indexOf("NotFoundError") != -1) {
            errorMessage = "Camera or Mic are not found or not allowed in your device";
        }
        else if (error.indexOf("NotReadableError") != -1 || error.indexOf("TrackStartError") != -1) {
            errorMessage = "Camera or Mic is being used by some other process that does not let read the devices";
        }
        else if(error.indexOf("OverconstrainedError") != -1 || error.indexOf("ConstraintNotSatisfiedError") != -1) {
            errorMessage = "There is no device found that fits your video and audio constraints. You may change video and audio constraints"
        }
        else if (error.indexOf("NotAllowedError") != -1 || error.indexOf("PermissionDeniedError") != -1) {
            errorMessage = "You are not allowed to access camera and mic.";
        }
        else if (error.indexOf("TypeError") != -1) {
            errorMessage = "Video/Audio is required";
        }
        else if (error.indexOf("getUserMediaIsNotAllowed") != -1){
            errorMessage = "You are not allowed to reach devices from an insecure origin, please enable ssl";
        }
        else if (error.indexOf("ScreenSharePermissionDenied") != -1) {
            errorMessage = "You are not allowed to access screen share";
            $(".video-source").first().prop("checked", true);						
        }
        else if (error.indexOf("WebSocketNotConnected") != -1) {
            errorMessage = "WebSocket Connection is disconnected.";
        }
        if(errorMessage == '"publishTimeoutError"'){
            $('.PublisherHolderPlayerLoader').css('display','none')
            alert('Please check your internet connection and try again');
        }else{
            if(errorMessage != '"already_publishing"'){
                alert(errorMessage);
            }
        }
    }
});

//initialize the WebRTCAdaptor


    //====================================== END NEW FUNCTION ========================///

    // var pc_config = null;

    // var sdpConstraints = {
    //     OfferToReceiveAudio: false,
    //     OfferToReceiveVideo: false

    // };

    // var mediaConstraints = {
    //     video: true,
    //     audio: true
    // };

    // let websocketURL = "ws://" + 'corsi.stream:5443/WebRTCAppEE/websocket';

    // var webRTCAdaptor = new WebRTCAdaptor({
    //     websocket_url: websocketURL,
    //     mediaConstraints: mediaConstraints,
    //     peerconnection_config: pc_config,
    //     sdp_constraints: sdpConstraints,
    //     localVideoId: "localVideo",
    //     debug: true,
    //     callback: function (info, obj) {
    //         console.log('streamInfo',info);
    //         if (info == "initialized") {
    //             console.log("initialized");
    //         } else if (info == "publish_started") {
    //             //stream is being published
    //             console.log("publish started");
    //             startAnimation();
    //         } else if (info == "publish_finished") {
    //             //stream is being finished
    //             console.log("publish finished");
    //         }
    //         else if (info == "screen_share_extension_available") {
    //             console.log("screen share extension available");
    //         }
    //         else if (info == "screen_share_stopped") {
    //             console.log("screen share stopped");
    //         }
    //         else if (info == "closed") {
    //             //console.log("Connection closed");
    //             if (typeof obj != "undefined") {
    //                 console.log("Connecton closed: " + JSON.stringify(obj));
    //             }
    //         }
    //         else if (info == "pong") {
    //             //ping/pong message are sent to and received from server to make the connection alive all the time
    //             //It's especially useful when load balancer or firewalls close the websocket connection due to inactivity
    //         }
    //         else if (info == "refreshConnection") {
    //             startPublishing();
    //         }
    //         else if (info == "updated_stats") {
    //             //obj is the PeerStats which has fields
    //             //averageOutgoingBitrate - kbits/sec
    //             //currentOutgoingBitrate - kbits/sec
    //             console.log("Average outgoing bitrate " + obj.averageOutgoingBitrate + " kbits/sec"
    //                 + " Current outgoing bitrate: " + obj.currentOutgoingBitrate + " kbits/sec");

    //         }
    //     },
    //     callbackError: function (error, message) {
    //         //some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError

    //         console.log(error,error);
    //         var errorMessage = JSON.stringify(error);
    //         if (typeof message != "undefined") {
    //             errorMessage = message;
    //         }
    //         var errorMessage = JSON.stringify(error);
    //         if (error.indexOf("NotFoundError") != -1) {
    //             errorMessage = "Camera or Mic are not found or not allowed in your device";
    //         }
    //         else if (error.indexOf("NotReadableError") != -1 || error.indexOf("TrackStartError") != -1) {
    //             errorMessage = "Camera or Mic is being used by some other process that does not let read the devices";
    //         }
    //         else if (error.indexOf("OverconstrainedError") != -1 || error.indexOf("ConstraintNotSatisfiedError") != -1) {
    //             errorMessage = "There is no device found that fits your video and audio constraints. You may change video and audio constraints"
    //         }
    //         else if (error.indexOf("NotAllowedError") != -1 || error.indexOf("PermissionDeniedError") != -1) {
    //             errorMessage = "You are not allowed to access camera and mic.";
    //         }
    //         else if (error.indexOf("TypeError") != -1) {
    //             errorMessage = "Video/Audio is required";
    //         }

    //         // alert(errorMessage);
    //     }
    // });


    return webRTCAdaptor;

}

export default webRTCAdaptorFunc;