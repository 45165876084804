import React, { useEffect } from 'react';
import Text from '../../lang/Text'
import $ from 'jquery';

const StartupModal = ({startStudentStream}) => {

	useEffect(() => {
		$('.at-startupmodal').modal('show');
		console.log('test');
		
	},[])


    return (
        <div className="at-thememodal modal at-startupmodal fade" id="startupModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
		<div className="modal-dialog at-modaldialog" role="document">
			<div className="modal-content at-modalcontent">
				<div className="modal-body at-modalbody">
					<button type="button" data-tip="Close" className="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
					<div className="at-startupmodalcontentholder">
						<div className="at-startupmodalcontent">
							<div className="at-description">
								<p><Text tid="studentStarupmodal" /></p>
								<p><Text tid="studentCameramic" /></p>
{/* 								<p>You are about to enter the class room.<span>Wait for the teacher to start the lesson video.</span></p>
								<p>Your microphone and camera will initially be closed.<span>If you want to open them,</span><span>Click those buttons of your box.</span></p> */}
							</div>
							<div className="at-lineimageholder">
								<div className="at-onlineclassmate">
									<figure className="at-onlineclassmateimg">
										<figcaption>
											<h4>Fairy Princess</h4>
											<span className="at-onlinedot at-bgonline"></span>
										</figcaption>
										<em>J</em>
										<div class="at-btnactions">
											<a class="at-btnaction" href="#"><i class="icon-mic"></i></a>
											<a class="at-btnaction" href="#"><i class="icon-video"></i></a>
										</div>
									</figure>
								</div>
								<div className="at-instructions">
									<span><Text tid="toOPenmicrophone"/></span>
									<span><Text tid="toOpencamera"/></span>
								</div>
							</div>
							<a className="at-btn" data-dismiss="modal" href="javascript:void(0);">
								<Text tid="enterTheclass"/>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    );
};

export default StartupModal;
