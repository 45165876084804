import React from 'react';
import Text from '../../lang/Text'

const DownloadChrome = () => {
    return (
        <div className="at-thememodal modal at-downloadChrome fade" id="at-downloadChrome" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
		<div className="modal-dialog at-modaldialog" role="document">
			<div className="modal-content at-modalcontent">
				<div className="modal-body at-modalbody">
					<button type="button" data-tip="Close" className="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
					<div className="at-startupmodalcontentholder">
						<div className="at-startupmodalcontent">
							<div className="at-description">
								<p><Text tid="downloadChromeTitle" /></p>
								<p><Text tid="downloadChromeText" /></p>
{/* 								<p>You are about to enter the class room.<span>Wait for the teacher to start the lesson video.</span></p>
								<p>Your microphone and camera will initially be closed.<span>If you want to open them,</span><span>Click those buttons of your box.</span></p> */}
							</div>

							<a className="at-btn" target="_blank" href="https://www.google.com/intl/en_pk/chrome/">
								Download
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    );
};

export default DownloadChrome;