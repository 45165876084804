import React,{useReducer} from 'react';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import Text from '../../lang/Text'
import Axios from 'axios'
import env from '../../env'
import Avatar from 'react-avatar'
import auth from '../../Auth/authinfo';
import reducer from '../Common/Reducer'
import { toast } from 'react-toastify';

const OpenStreamPermissionmodel = ({presenseChannel,classId,webrtc,setClassMembers,setMemberStreamStatus,memberStreamStatus}) => {

	//Student Allow audio stream in box
	let allowAudio = () => {
		let videoStatus = false;
		webrtc.turnOffLocalCamera()
		webrtc.unmuteLocalMic();
		setClassMembers({type: 'streamingAudioStatus',data : {id:auth.userId(),audio: true,publishedStream: true,video:false}})
		if(memberStreamStatus.published != true){
			Axios.get(env.baseUrl+"/active/stream/count/"+classId).then(({data}) => {
                if(data >= env.maxLimit){
					$('#at-').modal('show');
                    
                    return 0;
                }else{
					$('.PublisherHolderPlayerLoader'+auth.userId()).css('display','initial')
					setTimeout(() => {
						$('.PublisherHolderPlayerLoader'+auth.userId()).css('display','none')
					},4000)
					webrtc.publish('studentStream-'+auth.userId() + classId)
					Axios.get(env.baseUrl+"/increment/stream/count/"+classId)

                }
            })
			
		}else{
			if(memberStreamStatus.videoStatus == 'on'){
				webrtc.turnOnLocalCamera()
				videoStatus = true;
			}
		}
		presenseChannel.trigger("client-AskQuestion-" + classId,{
			type: 'studentHolderStreamStatus',
			status : 'streamStarted',
			userId : auth.userId(),
			name : auth.username(),
			audio : true,
			video : videoStatus,
		  })
		  setMemberStreamStatus({type : 'setMemberStreamStatus',state:{
			audioStatus : 'on',
			videoStatus : videoStatus ? 'on' : 'off',
			published : true
		}});
	}


	//Student Allow video stream in box
	let allowVideo = () => {
		webrtc.turnOnLocalCamera()
		webrtc.unmuteLocalMic();

		let streamAudio = true;
		if(memberStreamStatus.published != true){
			Axios.get(env.baseUrl+"/active/stream/count/"+classId).then(({data}) => {
                if(data >= env.maxLimit){
					$('#at-').modal('show');

                    return 0;
                }else{
					$('.PublisherHolderPlayerLoader'+auth.userId()).css('display','initial')
					webrtc.publish('studentStream-'+auth.userId() + classId)
					Axios.get(env.baseUrl+"/increment/stream/count/"+classId)

                }
            })
			
		}else{
			if(memberStreamStatus.audioStatus == 'off'){
				webrtc.muteLocalMic()
				streamAudio = false;
			}
		}
		
		setClassMembers({ type: 'memberStreamStatus', data: {id : auth.userId(), status:1,audio:streamAudio,video:true,publishedStream: true} });
		presenseChannel.trigger("client-AskQuestion-" + classId,{
			type: 'studentHolderStreamStatus',
			status : 'streamStarted',
			userId : auth.userId(),
			name : auth.username(),
			audio : streamAudio,
			video : true,
		  })
		  setMemberStreamStatus({type : 'setMemberStreamStatus',state:{
			audioStatus : streamAudio ? 'on' : 'off',
			videoStatus : 'on',
			published : true
		}});

		if (streamAudio) {
			setClassMembers({type: 'streamingAudioStatus',data : {id:auth.userId(),audio: true,video:true}})
		}else{
			setClassMembers({type: 'streamingAudioStatus',data : {id:auth.userId(),audio: false,video:true}})
		}
	}


	//Student reject open stream in box request 
	let rejectRequest = () => {
		console.log('trigger');
		setClassMembers({type: 'streamingAudioStatus',data : {id:auth.userId(),publishedStream: false,video:false,audio:false}})

		presenseChannel.trigger("client-AskQuestion-request" + classId,{
			type: 'reject',
			userId : auth.userId(),
			name : auth.username(),
			audio : true,
			video : true,
        })
	}

    return (
        <div className="at-thememodal modal at-modalpermission fade" id="exampleModal3" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
		<div className="modal-dialog at-modaldialog" role="document">
			<div className="modal-content at-modalcontent">
				<div className="modal-header at-modalheader">
					<h5 className="modal-title at-modaltitle" id="exampleModalLabel1"><Text tid="permission" /></h5>
					{/* <button type="button" data-tip="Close" className="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button> */}
				</div>
				<div className="modal-body at-modalbody">
					<div className="at-permissionontent">
						<figure className="at-permissionimg">
						<Avatar src={require('../../assets/images/class-meates/img-01.png')+"s"} size="200px" style={{width : "130px", height : "130px"}} name="teacher" />
						</figure>
						<div className="at-description">
							<p><strong><Text tid="teacher" /> <span id="teacherName1"> </span></strong>
								<div className="studentAudioPermissionicon"><Text tid="wantToTurnOnAudio" /></div>
								<div className="studentVideoPermissionicon"><Text tid="wantToTurnOnVideo" /></div>
							</p>
						</div>
						<ul className="at-btnpermisions">
							<ReactTooltip place="top" type="dark" effect="float"/>
							<li className="studentVideoPermissionicon">
								<span><Text tid="allow" /></span>
								<a className="at-btnpermision" onClick={() => {allowVideo()}} data-dismiss="modal"><i className="icon-video"></i></a>
							</li>
							<li className="studentAudioPermissionicon">
								<span><Text tid="allow" /></span>
								<a className="at-btnpermision" onClick={() => {allowAudio()}} data-dismiss="modal"><i className="icon-sound"></i></a>
							</li>
							<li>
								<span><Text tid="refuse" /></span>
								<a  onClick={() => {rejectRequest()}} data-tip="Deny Permission" className="close" data-dismiss="modal" className="at-btnpermision" ><i className="icon-cancel"></i></a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
    );
};

export default OpenStreamPermissionmodel;