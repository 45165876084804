import React, { useEffect, useState , useReducer} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import $ from 'jquery'
import Avatar from 'react-avatar'
import auth from '../../Auth/authinfo'
import Axios from 'axios'
import env from '../../env'
import { useParams } from 'react-router-dom'
import Text from '../../lang/Text';
import MemberHolderAntPlayer from '../Common/MemberHolderAntPlayer';
import teacherImage from '../../assets/images/teacher.svg'

const ClassMemberHolder = ({member,setQuestionuser,presenceChannel,classId,handle,setClassMembers,existingStreamId,onAllowAudio,webrtc,setMemberStreamStatus,memberStreamStatus,globalMicStatus,studentStreamEnabled,studentStreamId}) => {

    let allowClassMember = (data) => {
        	setQuestionuser(data);
			$('#exampleModal1').modal('show')
    }

    let params = useParams();
    classId = params.classId;
    let activeStreamStudentId = studentStreamId.replace('studentStream-', '');
    activeStreamStudentId = activeStreamStudentId.replace(classId, '');

    
    let kickUser = (e,member) => {
        e.preventDefault();
        
        presenceChannel.trigger("client-AskQuestion-" + classId,{
            type: 'kickOutFromClass',
            userId : member.id,
        })
        setClassMembers({ type: 'kickOutFromClass', memberId: member.id });
    }

    let allowAudio = async (e) => {
        e.preventDefault();
        if(member.online !== 1){
            return false;
        }
		await deleteExistingBroadcast()
		setClassMembers({ type: 'questionUserRemove', data: member})
		let streamId = 'studentStream-'+member.id+classId
		presenceChannel.trigger("client-AskQuestion-" + classId,{
			type: 'allowStudentStream',
			userId : member.id,
			audio : true,
			video : false,
			streamId : streamId
		})
		handle(true)

		if (onAllowAudio) {
			onAllowAudio(true,streamId,member.id);
		}
		
	}

    //Teacher request to add student on board.
	let allowVideo = async (e) => {
        if(member.online !== 1){
            return false;
        }
        // if(member.streamingStatus == 1){
        //     toast.error('Please close camera and mic of student before onboarding', {
        //         position: toast.POSITION.TOP_CENTER
        //     })
        //     return 0;
        // }
		// await deleteExistingBroadcast()
		// e.preventDefault();
		// setClassMembers({ type: 'questionUserRemove', data: member})

		let streamId = 'studentStream-'+member.id+classId
		presenceChannel.trigger("client-AskQuestion-" + classId,{
			type: 'allowStudentStreamRequest',
            userId : member.id,
            teacher: auth.userInfo(),
			audio : true,
            video : true,
            alreadyStreaming: member.streamingStatus,
			streamId : streamId
        })
    }

    //Teacher request student to allow stream in box
    let openMemeberVideo = (member,video) => {
        let activeStreamStudentId = existingStreamId.replace('studentStream-', '');
        activeStreamStudentId = activeStreamStudentId.replace(classId, '');

        if(activeStreamStudentId == member.id){
            toast.error(member.name + ' Already On boarded', {
                position: toast.POSITION.TOP_CENTER
            })
            return 0;
        }

        if(member.streamingStatus == 1){
            console.log(member);
            
            let trigger = {
                type: 'memberStreamToggle',
                userId : member.id,
                audio : member.audioStatus,
                video : member.videoStatus
            };
            if(video){
                if(member.videoStatus){
                    trigger.status = 'turnOffVideo'
                    trigger.video = false;
                }else{
                    trigger.status = 'turnOnVideo'
                    trigger.video = true;
                }
            }else{
                // if(member.audioStatus){
                //     trigger.status = 'turnOffAudio';
                //     trigger.audio = false;
                // }else{
                //     trigger.status = 'turnOnAudio'
                //     trigger.audio = true;
                // }
            }
            presenceChannel.trigger("client-AskQuestion-" + classId,trigger)
        }else{
            //Max Stream Limit Check Added    
            Axios.get(env.baseUrl+"/active/stream/count/"+classId).then(({data}) => {
                if(data >= env.maxLimit){
                    toast.error('Max Stream Limit Reached', {
                        position: toast.POSITION.TOP_CENTER
                    })

                    return 0;
                }else{//If stream limit is available then stream open request will be sent to student from teacher side
                    setClassMembers({ type: 'MEMBER_IN_PROGRESS', id: member.id })
                    presenceChannel.trigger("client-AskQuestion-" + classId,{
                        type: 'openStudentStreamRequest',
                        userId : member.id,
                        teacher: auth.userInfo(),
                        video: video
                    })
                }
            })
        }
    } 

    let deleteExistingBroadcast = async () => {
		if(existingStreamId !== ""){
			// console.log("stopPublished",existingStreamId);
			await Axios.delete(env.baseUrl+"/remove/stream/"+existingStreamId).then(res => console.log(res));
			
		}
    }

    let zoomStudent = (e,userId) => {
        e.preventDefault();
        setClassMembers({ type: 'zoomUser', id: userId })
    }

    let toggleVideo = (e) => {//Student toggle stream box video to turn on and off
        e.preventDefault();
        
        if(!memberStreamStatus.published){//Check if member is already streaming in the box
            //Max Stream Limit check added
            Axios.get(env.baseUrl+"/active/stream/count/"+classId).then(({data}) => {
                if(data >= env.maxLimit){
                    $('#at-maxStreamReached').modal('show');

                    return 0;
                }else{//If member is not already streaming and stream limit is still available then turn on camera, mic and start publish
                    webrtc.turnOnLocalCamera()
                    webrtc.muteLocalMic()
                    // webrtc.unmuteLocalMic()
                    setClassMembers({type: 'streamingAudioStatus',data : {id:auth.userId(),audio: false,video:true,publishedStream: true}})
                    webrtc.publish('studentStream-'+auth.userId() + classId)
                    Axios.get(env.baseUrl+"/increment/stream/count/"+classId)
                    $('.PublisherHolderPlayerLoader'+auth.userId()).css('display','initial')
                    setMemberStreamStatus({type : 'setMemberStreamStatus',state:{
                        audioStatus : 'off',
                        videoStatus : 'on',
                        published : true
                    }});
                    presenceChannel.trigger("client-AskQuestion-" + classId,{
                        type: 'studentHolderStreamStatus',
                        status : 'streamStarted',
                        initialized : true,
                        userId : member.id,
                        audio : false,
                        video : true,
                    })     
                }
            })

            }else{//If member is already streaming in the box then
            if(memberStreamStatus.audioStatus == 'off'){//If member audio is already off and student click on video icon in stream box
                if(memberStreamStatus.videoStatus === 'on'){
                    //If member video is on, means the student is streaming only video, in this case have to stop publish and gray video icon as well
                    webrtc.stop('studentStream-'+auth.userId() + classId)
                    Axios.get(env.baseUrl+"/decrement/stream/count/"+classId)
                    $('.PublisherHolderPlayerLoader'+auth.userId()).css('display','initial')
                    setTimeout(() => {
                        $('.PublisherHolderPlayerLoader'+auth.userId()).css('display','none')
                    },4000)

                    setMemberStreamStatus({type : 'setMemberStreamStatus',state:{
                        audioStatus : 'off',
                        videoStatus : 'off',
                        published : false
                    }});
                    setClassMembers({type: 'streamingAudioStatus',data : {id:auth.userId(),publishedStream: false,video:false,audio:false}})

                    presenceChannel.trigger("client-AskQuestion-" + classId,{
                        type: 'studentHolderStreamStatus',
                        status : 'streamEnded',
                        userId : member.id,
                        audio : false,
                        video : false,
                    })
                    Axios.delete(env.baseUrl+"/remove/stream/"+'studentStream-'+auth.userId() + classId).then(res => console.log(res));
                }
            }

            if(memberStreamStatus.audioStatus == 'on'){//If member audio is on + student already streaming in box and student click on video icon in stream box this case will work
                if(memberStreamStatus.videoStatus === 'on'){//If member video is already on and student click on video icon, means student want to off camera, in this case
                    webrtc.turnOffLocalCamera()

                    setMemberStreamStatus({type : 'setMemberStreamStatus',state:{
                        audioStatus : 'on',
                        videoStatus : 'off',
                        published : true
                    }});
                    setClassMembers({type: 'streamingAudioStatus',data : {id:auth.userId(),video:false,publishedStream: true}})

                    presenceChannel.trigger("client-AskQuestion-" + classId,{
                        type: 'studentHolderStreamStatus',
                        status : 'streamStarted',
                        userId : member.id,
                        audio : true,
                        video : false,
                    })
                }else{//If member audio is on + member streaming in box and video is off means student wants to turn on his camera while keep streaming audio
                    webrtc.turnOnLocalCamera()
                    setMemberStreamStatus({type : 'setMemberStreamStatus',state:{
                        audioStatus : 'on',
                        videoStatus : 'on',
                        published : true
                    }});
                    setClassMembers({type: 'streamingAudioStatus',data : {id:auth.userId(),publishedStream: true,video:true}})

                    presenceChannel.trigger("client-AskQuestion-" + classId,{
                        type: 'studentHolderStreamStatus',
                        status : 'streamStarted',
                        userId : member.id,
                        audio : true,
                        video : true,
                    })
                }
            }
        }
		
	}

	let toggleAudio = (e) => { // Student toggle audio to enable stream in box
        e.preventDefault();
        if(!memberStreamStatus.published){//If student is not already streaming in the box and try to toggle audio
            //Max Stream Limit Check
            Axios.get(env.baseUrl+"/active/stream/count/"+classId).then(({data}) => {
                if(data >= env.maxLimit){
                    $('#at-maxStreamReached').modal('show');

                    return 0;
                }else{//If stream limit check pass so it will turn on the mic, close the camera and start the publish
                    webrtc.turnOffLocalCamera()
                    webrtc.unmuteLocalMic()
                    setClassMembers({type: 'streamingAudioStatus',data : {id:auth.userId(),audio: true,publishedStream: true,video:false}})
                    $('.PublisherHolderPlayerLoader'+auth.userId()).css('display','initial')
                    setTimeout(() => {
                        $('.PublisherHolderPlayerLoader'+auth.userId()).css('display','none')
                    },4000)
                    webrtc.publish('studentStream-'+auth.userId() + classId)
                    Axios.get(env.baseUrl+"/increment/stream/count/"+classId)
                    setMemberStreamStatus({type : 'setMemberStreamStatus',state:{
                        audioStatus : 'on',
                        videoStatus : 'off',
                        published : true
                    }});
                    presenceChannel.trigger("client-AskQuestion-" + classId,{
                        type: 'studentHolderStreamStatus',
                        status : 'streamStarted',
                        initialized : true,
                        userId : member.id,
                        audio : true,
                        video : false,
                      })
                }
            })
			
        }else{//If student is already streaming in the box and student tries to toggle audio 
            if(memberStreamStatus.videoStatus == 'off'){//If student video is already off
                if(memberStreamStatus.audioStatus === 'on'){
                    //If member is streaming + video is already off and audio status is already on then means student was only streaming audio and wants to turn off audio as well in this case publish will stop
                    $('.PublisherHolderPlayerLoader'+auth.userId()).css('display','initial')
                    setTimeout(() => {
                        $('.PublisherHolderPlayerLoader'+auth.userId()).css('display','none')
                    },4000)
                    webrtc.stop('studentStream-'+auth.userId() + classId)
                    Axios.get(env.baseUrl+"/decrement/stream/count/"+classId)
                    presenceChannel.trigger("client-AskQuestion-" + classId,{
                        type: 'studentHolderStreamStatus',
                        status : 'streamEnded',
                        userId : member.id,
                        audio : false,
                        video : false,
                    })
                    setMemberStreamStatus({type : 'setMemberStreamStatus',state:{
                        audioStatus : 'off',
                        videoStatus : 'off',
                        published : false
                    }});
                    setClassMembers({type: 'streamingAudioStatus',data : {id:auth.userId(),publishedStream: false,video:false,audio:false}})
                    Axios.delete(env.baseUrl+"/remove/stream/"+'studentStream-'+auth.userId() + classId).then(res => console.log(res));
                }
            }

            if(memberStreamStatus.videoStatus == 'on'){//If student is already streaming and student video is on as well and student tries to toggle audio in stream box
                if(memberStreamStatus.audioStatus === 'on'){//If student is streaming + student video is on and audio is on as well in this case we will only mute his audio and gray audio icon
                    webrtc.muteLocalMic()
                    setClassMembers({type: 'streamingAudioStatus',data : {id:auth.userId(),audio: false,publishedStream: true,video:true}})
                    presenceChannel.trigger("client-AskQuestion-" + classId,{
                        type: 'studentHolderStreamStatus',
                        status : 'streamStarted',
                        userId : member.id,
                        audio : false,
                        video : true,
                      })
                    setMemberStreamStatus({type : 'setMemberStreamStatus',state:{
                        audioStatus : 'off',
                        videoStatus : 'on',
                        published : true
                    }});
                }else{//If student is already streaming + video is on and audio status is off so in this case we will unmute the mic and both audio and video will be enabled 
                    webrtc.unmuteLocalMic()
                    setClassMembers({type: 'streamingAudioStatus',data : {id:auth.userId(),audio: true,publishedStream: true,video:true}})
                    presenceChannel.trigger("client-AskQuestion-" + classId,{
                        type: 'studentHolderStreamStatus',
                        status : 'streamStarted',
                        userId : member.id,
                        audio : true,
                        video : true,
                      })
                    setMemberStreamStatus({type : 'setMemberStreamStatus',state:{
                        audioStatus : 'on',
                        videoStatus : 'on',
                        published : true
                    }});
                }
            }
        }
	}
    

    var memberNickName = member.name; 
    
    // console.log('streamStatusUpdate',member.memberStreamStatus);
    
    return (
        <div className={`at-onlineclassmate ${member.zoom == 1 ? 'at-zoom-user' : ''} ${member.role_id == 2? 'at-active at-teachebg' : 'at-studentbg'} ${member.online != 1 ? 'at-offline-user' : ''} `}>
            <figure className="at-onlineclassmateimg">
                <figcaption style={member.id == auth.userId() && !auth.isTeacher()? {backgroundColor : 'blue'} : {}}>
                    {member.role_id === 2 ? (<img className="at-teacheravatar" src={teacherImage} />) : ""}
                    <h4>{memberNickName}</h4>
                    <span className={member.online === 1 ?"at-onlinedot at-bgonline" : "at-onlinedot at-bgoffline"}></span>
                </figcaption>
                {member.askquestion === 1 ? 
                    (<i className={ member.askQuestionIcon }  onClick={() => {allowClassMember(member)}}></i>) :
                     (member.role_id == 3 && member.id != auth.userId() && member.online == 1 && member.onBoarded != true? 
                    //  (<iframe width="50%" height="100%" src={"https://corsi.stream:5443/WebRTCAppEE/play_embed_student.html?name=" + 'studentStream-'+member.id + classId} frameborder="0" allowFullScreen></iframe>) 
                     (<MemberHolderAntPlayer streamId={'studentStream-'+member.id + classId} member={member} setClassMembers={setClassMembers} />)
                     : 
                    (member.selfUser == 1 &&  memberStreamStatus != undefined && memberStreamStatus.videoStatus == 'on' && member.onBoarded != true? 
                    <MemberHolderAntPlayer streamId={'studentStream-'+member.id + classId} member={member} setClassMembers={setClassMembers} muted={true}/> : <Avatar src={require('../../assets/images/class-meates/img-01.png')+"s"} size="200px" style={{"maxWidth" : "240px", height : "180px"}} name={memberNickName} />)
                    
                    )
                }
                {auth.isTeacher() && member.selfUser !== 1 && member.online === 1? (
                <div className="at-btnactions" style={{left : '35%'}}>
                <a className="at-btnaction" href="#" onClick={ e => zoomStudent(e,member.id) }>
                        <i className="icon-zoom-in"></i>
                        <span className="at-themetooltip">
                            <Text tid={member.zoom == 1 ? 'closeZoomStudentVideo' : 'zoomStudentvideo'} /> 
                        </span>
                    </a>
                    
                    <span className="at-morebuttonshlder" style={(studentStreamEnabled && activeStreamStudentId == member.id)  || member.askquestion == 1 ? {display : 'none'} : {display : 'initial'}}>
                    <a className="at-btnaction" href="#" onClick={e => allowVideo(e)}>
                        <i className="icon-add"></i>
                        <span className="at-themetooltip">
                            <Text tid="openCameraOfStudent" /> 
                        </span>
                        {member.inProgress == 1 ? (<span className="at-memberloadingicon"><em><img src={require('../../assets/images/loading-spin.gif')}/></em></span>) : ''}
                    </a>
                    <a className={`at-btnaction ${member.audioStatus ? "mic active" :  "mic-mute"}`} href="#" onClick={e => openMemeberVideo(member,false)} style={globalMicStatus ? {visibility : 'visible'} : {visibility : 'hidden'}}>
                        <i className="icon-mic"></i>
                        <span className="at-themetooltip">
                            <Text tid={member.audioStatus ? "closeMic" : "openMic"} />
                        </span>
                        {member.inProgress == 1 ? (<span className="at-memberloadingicon"><em><img src={require('../../assets/images/loading-spin.gif')}/></em></span>) : ''}
                    </a>
                    <a className={`at-btnaction ${member.videoStatus ? "mic active" :  "mic-mute"}`} href="#" onClick={e => openMemeberVideo(member,true)}>
                        <i className="icon-video" />
                        <span className="at-themetooltip">
                            <Text tid={member.videoStatus ? "closeVideo" :"openVideo"} />
                        </span>
                        {member.inProgress == 1 ? (<span className="at-memberloadingicon"><em><img src={require('../../assets/images/loading-spin.gif')}/></em></span>) : ''}
                    </a>
                    </span>
                    <a className="at-btnaction" href="#" onClick={e => kickUser(e,member)}>
                        <i className="icon-side"></i>
                        <span className="at-themetooltip">
                            <Text tid="expellStudent" />
                        </span>
                    </a>
                    <div className={'PublisherHolderPlayerLoader PublisherHolderPlayerLoader'+member.id} style={{display : 'none'}}>
                        <span style={{ width: '140px', height : '100%', background : 'none',top : '-100px',left:'50px'}} className="at-memberloadingicon"><em><img src={require('../../assets/images/loading-spin.gif')}/></em></span>
                    </div>
                </div>) : ''}

                {member.selfUser == 1 && !auth.isTeacher() ? (
                <span style={studentStreamEnabled && activeStreamStudentId == member.id ? {display : 'none'} : {display : 'initial'}}>
                    <div className="at-btnactions">
                        <a className={`at-btnaction userMicBtn ${memberStreamStatus.audioStatus == "on"? "mic active" : " mic-mute" }`} href="#" onClick={e => toggleAudio(e,member)}>
                            <i className="icon-mic"></i>
                            <span className="at-themetooltip">
                                <Text tid={memberStreamStatus.audioStatus == "on" ? "closeMic" : "openMic"} />
                            </span>
                        </a>
                        <a className={`at-btnaction ${memberStreamStatus.videoStatus == "off" ? " mic-mute" : "mic active"}`} href="#" onClick={e => toggleVideo(e,member)}>
                            <i className={memberStreamStatus.videoStatus == "off" ? "icon-video-cancel" : "icon-video"}></i>
                        <span className="at-themetooltip">
                                <Text tid={memberStreamStatus.videoStatus == "off" ? "openVideo" : "closeVideo" } />
                            </span>
                        </a>

                        <div className={'PublisherHolderPlayerLoader PublisherHolderPlayerLoader'+member.id} style={{display : 'none'}}>
                            <span style={{ width: '140px', height : '100%', background : 'none',top : '-100px',left:'50px'}} className="at-memberloadingicon"><em><img src={require('../../assets/images/loading-spin.gif')}/></em></span>
                        </div>
                    </div>
                </span>
                ) : ''}
                {/* <img src="../../images/teacher-avatar.png"/> */}
            </figure>
        </div>
    );
};

export default ClassMemberHolder;