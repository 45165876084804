import it from './it';
import en from './en';

export const dictionaryList = {
  it,
  en
};

export const languageOptions = [
  { id: 'it', text: 'Italiano' , flag: 'italy.png'},
  { id: 'en', text: 'English', flag: 'uk.png'}
];