import React from 'react';
import Avatar from 'react-avatar'
import auth from '../../Auth/authinfo'

const MessageHolder = ({ message, chatUser, isSameUser, devider }) => {
    let contents;
    let contents2 = '';

    if(devider !== null && devider !== undefined){
        contents2 = (
            <div className="at-messagetime">
				<span>{devider}</span>
			</div>
        );
    }        
    if (message.type == "file") {
        let icon = message.file_type.split("/")[1]
       
        contents = (
            
            <div className={message.is_teacher === 1 ? "at-messageholder at-sendermessage" : "at-messageholder"}>
                {!isSameUser ?
                    <div className="at-chatimgname">
                        <figure className="at-classmateimg">
                            <Avatar src={chatUser+"sd"} round={true} style={{marginLeft : -3, marginTop : -3}} size="50px" name={message.user} />
                        </figure>
                        <h3>{message.user}</h3>
                        <time dateTime="2020-12-12" className="at-chatmessagetime">{message.time}</time>
                    </div>
                    : ''}
                <div className={`at-message at-chatfiles ${message.visible_only != null ? 'at-hasPrivatemessage' : ''}`}>
                    <div className="at-chatfileholder">
                        <i className={"icon-pdf-file"}></i>
                        <div className="at-filenamesize">
                            <h4>{message.fileName}</h4>
                            <span>{message.file_type} - {message.size}</span>
                        </div>
                    </div>
                    <div className="at-filebtnholder">
                        <a download={message.fileName} className="at-btnchatfile at-btndownload" target="_blank" href={message.url}>Download</a>
                        <a className="at-btnchatfile at-btnpreview" target="_blank" href={message.url}>Preview</a>
                    </div>
                </div>
            </div>
        );
    } else if (message.type == 'recording'){
            {/* Video Tagg Div Start */}
            contents = (<div className="at-messageholder">
                <div className="at-chatimgname">
                    <figure className="at-classmateimg">
                        <Avatar src={chatUser+"sd"} round={true} style={{marginLeft : -3, marginTop : -3}} size="50px" name={message.user} />
                    </figure>
                    <h3>{message.user}</h3>
                    <time dateTime="2020-12-12" className="at-chatmessagetime">{message.time}</time>
                </div>
                <div className="at-message">
                    <div className="at-videolink">
                        <a href={message.content} target="_blank">Click Here To Open In New Tab</a>
                    </div>
                    <video height="240" controlsList="nodownload">
                        <source src={message.content}></source>
                    </video>
                </div>
            </div>)
        {/* Video Tagg Div End */}

    }else{
        contents = (
            <div className={message.is_teacher === 1 ? "at-messageholder at-sendermessage" : "at-messageholder"}>
                {!isSameUser?
                    <div className="at-chatimgname">
                        <figure className="at-classmateimg">
                        <Avatar src={chatUser+"sd"} round={true} style={{"marginLeft" : -3, "marginTop" : -3}} size="50px" name={message.user} />
                        </figure>
                        <h3>{message.user}</h3>
                        <time dateTime="2020-12-12" className="at-chatmessagetime">{message.time}</time>
                    </div>
                    : ''}
                <div className={`at-message ${message.visible_only != null ? 'at-hasPrivatemessage' : ''}`}>
                    <p>{message.content}
                    {message.visible_only != null ? 
                    <span className="privateMessageClass">
                         (Private Message {message.visible_only != auth.userId() ? "To " + message.visible_only_user : ''})
                    </span> 
                    : ''}
                    </p>

                </div>
            </div>)
    }

    return (
        <>
        {contents2}
        {contents}
        </>
    );
};

export default MessageHolder;