import React from 'react';
import Avatar from 'react-avatar';


const ClassMemberList = ({classMembers,setSelectedChatMember,selectedChatMember}) => {

    let selectedUser = (e,member) => {
        e.preventDefault();
        if(selectedChatMember !== null && selectedChatMember.id === member.id){
            setSelectedChatMember(null)
        }else{
            var memberNickName = member.customer !== undefined && member.customer.nickName !== undefined ? member.customer.nickName : member.name;
            setSelectedChatMember({
                id : member.id,
                name: memberNickName
            })
        }
        
    }
    return (
        <div className="at-classmatesholder">
            {classMembers.map((member,i) => {
                var memberNickName = member.customer !== undefined && member.customer.nickName !== undefined ? member.customer.nickName : member.name;
                return (<a href="/" className={selectedChatMember != null && selectedChatMember.id === member.id ? "at-classmate active" : "at-classmate"} key={i} onClick={e => selectedUser(e,member)}>
                    <figure className="at-classmateimg">
                        {member.role_id === 3 ? (
                            <Avatar src={require('../../assets/Portale-Corsi-Logo-icons.png')} round={true} size="40px" name={memberNickName} />
                        ) : (
                            <Avatar src={require('../../assets/images/class-meates/img-01.png')+"s"} round={true} size="40px" name={memberNickName} />
                        )}
                        <span className="at-onlinedot at-bgidle"></span>
                    </figure>
                    <h4>{memberNickName}</h4>
                </a>)
            })}
        </div>
    );
};

export default ClassMemberList;