import React , {useContext, useEffect } from 'react';
import { languageOptions } from '../lang/languages'
import { LanguageContext } from '../Hooks/MultiLanguage/MultiLangContext'
import Text from './Text'
import $ from 'jquery'
import { jqueryThemeFunc } from '../assets/js/themefunction';


const LangSelector = () => {
    const languageContext = useContext(LanguageContext);
     
    useEffect(() => {
        jqueryThemeFunc()
    })
    let handleLangChange = (id) => {
        const selectedLanguage = languageOptions.find(item => item.id === id);
        languageContext.setLanguage(selectedLanguage);
        $('.at-langauges').css('display','none');

    } 

    let changeLang = e => {
        e.preventDefault();
        $('.at-selectlangauge').toggleClass('at-openlanguagedropdown');
        $('.at-langauges').slideToggle();
    }
    return (
        <div className="at-selectlangauge">
            <a id="at-btnopenlanguage" style={{width: '155px'}} className="at-btnopenlanguage" href="#" onClick={e => changeLang(e)}>
                {languageContext.language.flag != undefined ?(<img src={require('../assets/images/flags/'+languageContext.language.flag)} />) : ''}
                &nbsp; <Text tid="selectLang"/>
            </a>
            <ul className="at-langauges">
                {languageOptions.map((option,i) => {
                    return (
                        <li key={i}>
                            <a href="" onClick={(e) => { e.preventDefault(); handleLangChange(option.id)}}>
                                <img src={require('../assets/images/flags/'+option.flag)} align="Flag Image" />
                                &nbsp;<span>{option.text}</span>
                            </a>
                        </li>
                    )
                })}
            </ul>
    </div>
    );
};

export default LangSelector;