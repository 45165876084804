import React, {useState} from 'react';
import logo from '../assets/images/logo.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LangSelector from '../lang/LangSelector'
import Text from '../lang/Text';
import Axios from 'axios';
import env from '../env'


const ForgotPassword = () => {

	const [email,setEmail] = useState('');
	const [inProgress, setInProgeress] = useState(false)

	let forgetPasswordEmail = (e) => {
		e.preventDefault();

		if (email == '') {
			alert('Please fill out email field');
			return 0;
		}

		setInProgeress(true);

		Axios.post(env.baseUrl+'/forget-password',{email:  email}).then( ({data}) => {
			setInProgeress(false)
			if (data.status_code === 200){
				setInProgeress(false);
				setEmail('')
				toast.success('Password has been sent to your email address', {
                    position: toast.POSITION.TOP_RIGHT
                  });
            }else{
                toast.error(data.error, {
                    position: toast.POSITION.TOP_RIGHT
                  });
            }
        }).catch(e => setInProgeress(false));
	}

    return (
        <div>
            <ToastContainer />
            <div className="at-signinpageholder">
			<div className="at-singinpageimg"></div>
			<div className="at-singinpage">
				<div className="at-pagehead">
					<div className="at-logoimg">
						<img src={logo} alt="logo imag"/>
					</div>
					<LangSelector />
				</div>
				<div className="at-pageheading">
                <h3><em>Portale Corsi</em> <Text tid="welcomeTo" /></h3>
				</div>
				<div className="at-signinpageform">
					<form className="at-formtheme" onSubmit={forgetPasswordEmail}>
						<fieldset>
                            <legend><Text tid="PleaseEnterEmail"/></legend>
							<div className="form-group">
								<label>Email</label>
								<input type="text" placeholder="Please enter email.." value={email} onChange={e => setEmail(e.target.value)}/>
								<i className="icon-user"></i>
							</div>
							{/* <div className="form-group">
								<label><Text tid="password" /></label>
								<input type="password" name="password" placeholder="••••••••••"/>
								<i className="icon-lock"></i>
							</div> */}
							{/* <a className="at-btnforgot" href="javascript:void(0);"><Text tid="forgotPassword" /></a> */}
							<div className="form-group">
								<button className="at-signin" type="submit"><Text tid="SendPassword" />
								{inProgress ? (<img src={require('../assets/loader.gif')} />) : ''}
								</button>
							</div>
							{/* <div className="form-group">
								<span className="at-btnsingup"><Text tid="dontHaveAccount" /><a href="signup.html"> <Text tid="signUp" /></a></span>
							</div> */}
						</fieldset>
					</form>
					<span className="at-copyright">© Copyright 2020</span>
				</div>
			</div>
		</div>
        </div>
    );
};

export default ForgotPassword;