import React, { useEffect, useState, useReducer, useContext } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom'
import $ from 'jquery'
import logo from '../../assets/images/logo.png'
import answer_question_image from '../../assets/images/answer-qustion.svg'
import ask_question_image from '../../assets/images/ask-question.svg'
import chatUser from '../../assets/images/class-meates/img-01.png'
import MessageHolder from '../Teacher/MessageHolder';
import auth from '../../Auth/authinfo'
import Pusher from 'pusher-js';
import Axios from 'axios';
import env from '../../env'
import webRTCAdaptor from '../../Webrtc/webrtc';
import ClassMemberHolder from '../Teacher/ClassMemberHolder';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProfileComponent from '../Common/ProfileComponent';
import LangSelector from '../../lang/LangSelector';
import reducer from '../Common/Reducer'
import Text from '../../lang/Text'
import { LanguageContext } from '../../Hooks/MultiLanguage/MultiLangContext'
import ClassMemberList from '../Common/ClassMemberList';
import ClassRoomLoader from '../Loader/ClassRoomLoader'
import { confirmAlert } from 'react-confirm-alert';
import ReactTooltip from 'react-tooltip';
import 'react-confirm-alert/src/react-confirm-alert.css';
import teacherIcon from '../../assets/images/teachers.svg'
import PermissionModal from './PermissionModal';
import StartupModal from './StartupModal';
import AntPlayer from '../Common/AntPlayer';
import OpenStreamPermissionmodel from './OpenStreamPermissionModel';
import questionAnswer from '../../assets/images/answer-qustion.svg'
import Avatar from 'react-avatar'
import RecordingStarted from './RecordingStarted';


let muteMic = false;


const StudentStartLesson = () => {
    const [messages, setMessages] = useState([]);
    const [dateToday, setDateToday] = useState('');
    const [message, setMessage] = useState('');
    const [messagePage, setMessagePage] = useState(1);
    const [displayPrevMessageBtn, setDisplayPrevMessageBtn] = useState(false);
    const [displayMessageLoader, setDisplayMessageLoader] = useState(false);
    const [videoStatus, setVideoStatus] = useState('on');
    const [audioStatus, setAudioStatus] = useState('on');
    const [nm, setNm] = useState({});
    const [channel, setChannel] = useState({});
    const [webrtc, setWebrtc] = useState(null);
    const [virtualClass, setVirtualClass] = useState({})
    const [uploadProgress, updateUploadProgress] = useState(0);
    const [uploadStatus, setUploadStatus] = useState(false);
    const [studentStreamEnabled, setStudentStreamStatus] = useReducer(reducer2, false);
    const [studentStreamUser, setStudentStreamUser] = useReducer(reducer, {})
    const [teacherStreamUser, setTeacherStreamUser] = useState({})
    const [uploading, setUploading] = useState(false);
    const [classMembers, setClassMembers] = useReducer(reducer, [])
    const [onlineMembers, setOnlineMembersCount] = useReducer(reducer, 0);
    const [selectedChatMember, setSelectedChatMember] = useState(null);
    const [studentStreamId, setStudentStreamId] = useReducer(reducer, "")
    const [displayChatDot, setDisplayChatDot] = useState(false);
    const languageContext = useContext(LanguageContext)
    const [loading, setLoading] = useState(true);
    const [fullScreenChat, setFullScreenChat] = useState(false);
    const [fullScreen, setFullScreen] = useReducer(reducer, false);
    const [showClasses, setShowClasses] = useState(false);
    const [questionAsked, setQuestionAsked] = useState(false);
    const [infoAsked, setInfoAsked] = useState(false);
    const [streamStatus, setStreamStatus] = useState(false);
    const [streamRestart, setStreamRestart] = useState(false)
    const [screenSharing, setScreenSharing] = useState(false);
    const [splitScreenClass, setSplitScreenClass] = useState('at-teacherstudentview');
    const [memberStreamStatus, setMemberStreamStatus] = useReducer(reducer, { audioStatus: "off", videoStatus: "off", published: false });
    const [micStatus, setMicStatus] = useState({})


    let { classId } = useParams();
    let history = useHistory()
    let chatAreaEl;

    // Initial Effect
    useEffect(() => {
        Axios.get(env.baseUrl + "/class/info/" + classId, {
            'headers': {
                Authorization: 'Bearer ' + auth.authToken(),
                'content-type': 'multipart/form-data'
            },
        }).then(({ data }) => {
            if (data.enrolled_status) {
                setVirtualClass(data.class);
                setClassMembers({ type: 'addMembers', data: data.data.classMembers });
                setLoading(false)
            } else {
                history.push('/')
            }
        }).catch(e => console.log(e));

        let webrtcObj = webRTCAdaptor();
        setWebrtc(webrtcObj);


        const pusher = new Pusher(env.PUSHER_APP_KEY, {
            cluster: env.PUSHER_APP_CLUSTER,
            forceTLS: true,
            auth: {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + auth.authToken()
                }
            },
            userId: auth.userId(),
            userInfo: {},
            authEndpoint: env.baseUrl + "/pusher/auth"
        });

        const presenceChannel = pusher.subscribe('presence-class-' + classId);
        setChannel(presenceChannel)
        presenceChannel.bind('pusher:member_added', function (member) {
            setOnlineMembersCount({ type: 'increment' });
            // setClassMembers({ type: 'addMembers', data: member.info.userInfo })
            setClassMembers({ type: 'memberOnline', data: member.info.userInfo })

            // console.log(studentStreamUser,"studentStreamUser")
            presenceChannel.trigger("client-AskQuestion-" + classId, {
                type: 'studentHolderStreamStatus',
                status: 'streamStarted',
                initialized: true,
                userId: studentStreamUser.id,
                audio: studentStreamUser.audioStatus,
                video: studentStreamUser.videoStatus,
            })
            // console.log('member_added', member.info)
            if (member.info.userInfo.role_id == 2) {
                setTeacherStreamUser({ name: member.info.userInfo.name, videoStatus: true })
                // classStreamRestart();
            }
        });

        presenceChannel.bind('pusher:member_removed', function (member) {
            setOnlineMembersCount({ type: 'decrement' });
            // setClassMembers({ type: 'removeMember', data: member.info.userInfo })
            // if(member)
            setClassMembers({ type: 'memberOffline', data: member.info.userInfo })
            console.log('member_remove', member);

            if (member.info.userInfo.role_id == 2) {
                webrtcObj.stop('studentStream-' + auth.userId() + classId)

                setStudentStreamStatus({ type: 'studentStreamRemoved' })
                setClassMembers({ type: 'studentRemovedFromOnbaord', data: { id: auth.userId(), webrtc: webrtcObj, streamId: 'studentStream-' + auth.userId() + classId } })
                setClassMembers({ type: 'AudioStatusOnRemoveOnBoard', data: { id: auth.userId(), webrtc: webrtcObj, streamId: 'studentStream-' + auth.userId() + classId } })
                setClassMembers({ type: 'setOnbaordUser', id: member.id, status: false })
                setMemberStreamStatus({
                    type: 'setMemberStreamStatus', state: {
                        audioStatus: 'off',
                        videoStatus: 'off',
                        published: false
                    }
                });
                setClassMembers({ type: 'streamingAudioStatus', data: { id: auth.userId(), audio: false, video: false } })

                classEnded();
            }

        });


        presenceChannel.bind('client-AskQuestion-' + classId, (data, metadata) => {

            console.log("trigger", data);
            if (data.type === 'allowStudentStream') {//When student onboarded and accepted the permission
                setStudentStreamId({ type: 'streamId', streamId: data.streamId })
                setClassMembers({ type: 'setActiveStreamUserOnline', data: data.userId })
                setStudentStreamUser({ type: 'setState', data: { name: data.name, videoStatus: data.video, audioStatus: data.audio } })
                setClassMembers({ type: 'setOnbaordUser', id: data.userId, status: true })

                if (auth.userId() === data.userId) {
                    setQuestionAsked(false);
                    setInfoAsked(false);
                    // toast.success("Request to ask question granted", {
                    //     position: toast.POSITION.TOP_CENTER
                    // });
                    setStudentStreamStatus({ type: 'studentStream', data: 'streamer', classId: classId, metadata: data })
                    webrtcObj.unmuteLocalMic();

                    if (data.video) {
                        setVideoStatus('on');
                        webrtcObj.turnOnLocalCamera()
                    } else {
                        setVideoStatus('off');
                        webrtcObj.turnOffLocalCamera();
                    }

                    if (!data.published) {
                        webrtcObj.publish(data.streamId)
                    }
                } else {
                    setStudentStreamStatus({ type: 'studentStream', data: 'listner', classId: classId, metadata: data })
                }
            } else if (data.type === 'studentStreamRemoved') {//Student Removed from onboard

                // if(!memberStreamStatus.published){
                //     Axios.delete(env.baseUrl + "/remove/stream/" + studentStreamId).then(res => {

                //     });
                //     webrtcObj.stop('studentStream-'+auth.userId() + classId)
                // }
                setStudentStreamStatus({ type: 'studentStreamRemoved' })
                setClassMembers({ type: 'studentRemovedFromOnbaord', data: { id: auth.userId(), webrtc: webrtcObj, streamId: 'studentStream-' + auth.userId() + classId } })
                setClassMembers({ type: 'AudioStatusOnRemoveOnBoard', data: { id: auth.userId(), webrtc: webrtcObj, streamId: 'studentStream-' + auth.userId() + classId } })

                setClassMembers({ type: 'setOnbaordUser', id: data.userId, status: false })

                if (auth.userId() == data.userId) {
                    toast.success("Removed from onboard", {
                        position: toast.POSITION.TOP_CENTER
                    });
                }

            } else if (data.type === 'studentTurnOnVideo') {//Teacher turn on video of onboarded student


                if (data.userId === auth.userId()) {
                    setVideoStatus('on');
                    setStudentStreamStatus({ type: 'studentTurnOnVideo' })

                } else {
                    // Update Student State For Other Joined Students 
                    setStudentStreamUser({
                        type: "setState",
                        data: {
                            name: data.name,
                            videoStatus: true,
                            audioStatus: data.audioStatus == "on" ? true : false,
                        },
                    });
                }
            } else if (data.type === "studentTurnOffVideo") {//Teacher turn off video of onboarded student


                if (data.userId === auth.userId()) {
                    setVideoStatus('off');
                    setStudentStreamStatus({ type: "studentTurnOffVideo" })

                } else {
                    // Update Student State For Other Joined Students 
                    setStudentStreamUser({
                        type: "setState",
                        data: {
                            name: data.name,
                            videoStatus: false,
                            audioStatus: data.audioStatus == "on" ? true : false,
                        },
                    });
                }
            } else if (data.type === 'studentTurnOnAudio') {//Teacher turn on mic of onbaorded student


                if (data.userId === auth.userId()) {
                    setAudioStatus('on');
                    setStudentStreamStatus({ type: 'studentTurnOnAudio' })

                }
            } else if (data.type === 'studentTurnOffAudio') {//Teacher Mute mic of onboarded student


                if (data.userId === auth.userId()) {
                    setAudioStatus('off');
                    setStudentStreamStatus({ type: 'studentTurnOffAudio' })
                }
            } else if (data.type === 'allowStudentStreamRequest') {//Teacher ask the student permissionto come on board
                if (data.userId == auth.userId()) {
                    $('#exampleModal2').modal('show')
                    $('#teacherName').contents().remove();
                    $('#teacherName').append(data.teacher.name);
                }

            } else if (data.type == 'toggleVideo') {
                setStudentStreamUser({ type: 'setState', data: { name: data.name, videoStatus: data.status } })

            }

            if (data.type == 'RecordingStrated') {//Teacher started the recording
                $('.recordingIcon').css('visibility', 'visible')
                $('#at-recordingStarted').modal('show')
            }


            if (data.type == 'RecordingStopped') {//Teacher stopped the recording
                $('.recordingIcon').css('visibility', 'hidden')
            }

            if (data.type == 'kickOutFromClass') {//Student kicked out from class
                if (data.userId == auth.userId()) {
                    sessionStorage.clear();
                    pusher.disconnect();
                    window.location.replace('/' + classId)
                } else {
                    setClassMembers({ type: 'kickOutFromClass', memberId: data.userId });
                }
            }

            if (data.type == 'requestReceived' && data.userId == auth.userId()) {//Received request by teacher to come on baord
                toast.success(languageContext.dictionary['requestReceived'], {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            if (data.type === 'permissionDenied' && auth.userId() === data.userId) {//Teacher denied the request to ask question/ answer question
                setQuestionAsked(false);
                setInfoAsked(false);
                toast.error(languageContext.dictionary['requestDenied'], {
                    position: toast.POSITION.TOP_CENTER
                })
            }

            if (data.type === 'classEnded') {//Class is ended
                classEnded();
            }

            if (data.type === 'classStreamRestart') {
                classStreamRestart();
            }

            if (data.type === 'globalMicOff') {//Turn off mic globally
                console.log('micOff');
                $('.userMicBtn').css('visibility', 'hidden')
                // webrtcObj.muteLocalMic()
                setClassMembers({ type: 'globalMicOff', data: { id: auth.userId(), webrtc: webrtcObj } })
            }


            if (data.type === 'globalMicOn') {//Turn on mic globally and keep the mic mute that already muted
                setClassMembers({ type: 'globalMicOn', data: { id: auth.userId(), webrtc: webrtcObj } })
                $('.userMicBtn').css('visibility', 'visible')
                setStudentStreamStatus({ type: 'studentTurnOnAudioGlobalMic' })
            }


            if (data.type === 'openStudentStreamRequest') {//Teacher ask for permission to open stream in box
                if (data.userId == auth.userId()) {
                    console.log(data);

                    if (data.video) {
                        $('.studentVideoPermissionicon').css('display', 'initial');
                        $('.studentAudioPermissionicon').css('display', 'none');
                    } else {
                        $('.studentVideoPermissionicon').css('display', 'none');
                        $('.studentAudioPermissionicon').css('display', 'initial');
                    }
                    $('#exampleModal3').modal('show')
                    $('#teacherName1').contents().remove();
                    $('#teacherName1').append(data.teacher.name);
                }

            }


            if (data.type === 'memberStreamToggle') {//Toggle stream box audio and video by teacher, Teacher close student's audio/video from stream box

                if (data.userId == auth.userId()) {
                    toggleMemberStream(data, webrtcObj, presenceChannel)

                }

            }

            if (data.type == 'teacherStreamToggle') {//Teacher video start or stop
                setTeacherStreamUser({ name: data.userInfo.name, videoStatus: data.videoStatus })
            }

        })

        presenceChannel.bind('pusher:subscription_succeeded', function (members) {
            if (members.count > 0) {
                const nMembers = [];

                members.each(function (member) {
                    setOnlineMembersCount({ type: 'increment' });
                    setClassMembers({ type: 'memberOnline', data: member });

                    // nMembers.push(member.info.userInfo);
                });

                // setClassMembers({ type: 'addMembers', data: nMembers });
            }
        })

        const channel = pusher.subscribe('chatGroup' + classId);

        const authChannel = pusher.subscribe('AuthUser' + auth.userId());

        authChannel.bind('Logout', function () {
            window.sessionStorage.clear();

            if (classId !== undefined) {
                window.location.replace('/' + classId)
            } else {
                window.location.replace('/')
            }
        })

        Axios.get(env.baseUrl + "/class/messages/" + classId + "?page=" + messagePage, {
            'headers': {
                Authorization: 'Bearer ' + auth.authToken(),
                'content-type': 'multipart/form-data'
            }
        }).then(({ data }) => {
            if (data.total_messages > data.messages.length) {
                setDisplayPrevMessageBtn(true);
            }
            setMessagePage(data.page)
            setMessages(data.messages);
            setTimeout(() => { scrollToBottom() }, 20)
        })

        let playSound = () => {
            let url = require('../../assets/message.mp3');
            let audio = new Audio(url);
            audio.play();

        }

        channel.bind('NewGroupMessage', data => {
            console.log('bing pusher', data.data.userId);
            if (data.data.userId !== auth.userId()) {
                if (data.data.visible_only == null || data.data.visible_only == auth.userId()) {
                    data.data.date = today;
                    setNm(data.data);
                    playSound()
                    setTimeout(() => { scrollToBottom() }, 50)
                    let element = document.getElementsByClassName('at-chathead');
                    if (element[0] && element[0].clientWidth < 100) {
                        setDisplayChatDot(true);
                    }
                }
            }
        });

        let today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = yyyy + '-' + mm + '-' + dd;
        setDateToday(today)

        // setTimeout(function () {
        //     alert('published')
        //     webrtcObj.publish('studentStream-'+auth.userId() + classId)
        // }, 3000)
    }, []);

    const setDotIfFullScreenOn = () => {
        if (fullScreen) {
            setDisplayChatDot(true);
        }
    }


    useEffect(() => {
        if (typeof nm.userId !== 'undefined') {
            setMessages(messages.concat(nm));
            setDotIfFullScreenOn();
        }

    }, [nm]);


    let toggleMemberStream = (data, webrtc, channel) => {
        console.log('toggleMemberStream', data);

        if (data.audio == false && data.video == false) {
            setMemberStreamStatus({
                type: 'setMemberStreamStatus', state: {
                    audioStatus: 'off',
                    videoStatus: 'off',
                    published: false
                }
            });
            setClassMembers({ type: 'streamingAudioStatus', data: { id: auth.userId(), audio: false, video: false } })

            channel.trigger("client-AskQuestion-" + classId, {
                type: 'studentHolderStreamStatus',
                status: 'streamEnded',
                userId: auth.userId(),
                audio: false,
                video: false,
            })

            webrtc.stop('studentStream-' + auth.userId() + classId)
            Axios.get(env.baseUrl + "/decrement/stream/count/" + classId)
            setClassMembers({ type: 'memberStreamStatus', data: { id: auth.userId(), status: 0, publishedStream: false } });

            $('.PublisherHolderPlayerLoader' + auth.userId()).css('display', 'initial')
            setTimeout(() => {
                $('.PublisherHolderPlayerLoader' + auth.userId()).css('display', 'none')
            }, 4000)

        } else {

            if (data.status == 'turnOnAudio') {
                $('.studentVideoPermissionicon').css('display', 'none');
                $('.studentAudioPermissionicon').css('display', 'initial');
                $('#exampleModal3').modal('show')

            }
            if (data.status == 'turnOffAudio') {
                webrtc.muteLocalMic();
                setClassMembers({ type: 'streamingAudioStatus', data: { id: auth.userId(), audio: false, video: true } })

                setMemberStreamStatus({
                    type: 'setMemberStreamStatus', state: {
                        audioStatus: 'off',
                        videoStatus: 'on',
                        published: true
                    }
                });

                channel.trigger("client-AskQuestion-" + classId, {
                    type: 'studentHolderStreamStatus',
                    status: 'streamStarted',
                    userId: auth.userId(),
                    audio: false,
                    video: true,
                })
            }

            if (data.status == 'turnOnVideo') {
                $('.studentVideoPermissionicon').css('display', 'initial');
                $('.studentAudioPermissionicon').css('display', 'none');
                $('#exampleModal3').modal('show')

            }
            if (data.status == 'turnOffVideo') {
                webrtc.turnOffLocalCamera();

                setMemberStreamStatus({
                    type: 'setMemberStreamStatus', state: {
                        audioStatus: 'on',
                        videoStatus: 'off',
                        published: true
                    }
                });
                setClassMembers({ type: 'streamingAudioStatus', data: { id: auth.userId(), audio: true, video: false } })

                channel.trigger("client-AskQuestion-" + classId, {
                    type: 'studentHolderStreamStatus',
                    status: 'streamStarted',
                    userId: auth.userId(),
                    audio: true,
                    video: false,
                })
            }

        }
    }
    let startPublish = () => {
        webrtc.publish('studentStream-' + classId);
    }

    let startStudentStream = () => {
        webrtc.publish('studentStream-' + auth.userId() + classId)
        setClassMembers({ type: 'memberStreamStatus', data: { id: auth.userId(), status: 1 } });

    }

    let scrollToBottom = () => {
        // console.log('Scrooll',document.getElementById("chatArea"));
        chatAreaEl = document.getElementById("chatArea")
        if (chatAreaEl !== null) {
            chatAreaEl.scrollTop = chatAreaEl.scrollHeight
        }
    }

    let handleGlobalMic = () => {
        setClassMembers({ type: 'GlobaMicStatus', memberId: auth.userId() })
    }

    function handleMessageSubmit(e) {
        e.preventDefault();
        if (message == '' || message == null || typeof (message) == undefined) {
            return;
        }
        let messageObj = {
            user: auth.username(),
            userId: auth.userId(),
            self: 1,
            content: message,
            classId: classId,
            date: dateToday

        };

        if (selectedChatMember != null) {
            messageObj.visible_only = selectedChatMember.id;
        }
        setMessages(messages.concat(messageObj));

        setTimeout(() => { scrollToBottom() }, 20)

        Axios.post(env.baseUrl + '/send/message', { messageObj }, {
            'headers': {
                Authorization: 'Bearer ' + auth.authToken(),
                'content-type': 'multipart/form-data'
            }
        }).then(data => {
        });

        setMessage("")
    }

    let fetchPreviousMessages = (e) => {
        e.preventDefault();
        setDisplayMessageLoader(true);
        setDisplayPrevMessageBtn(false)
        Axios.get(env.baseUrl + "/class/messages/" + classId + "?page=" + messagePage, {
            'headers': {
                Authorization: 'Bearer ' + auth.authToken(),
                'content-type': 'multipart/form-data'
            }
        }).then(({ data }) => {
            setDisplayMessageLoader(false);
            let oldMessages = [...messages];
            if (data.messages.length > 0) {
                data.messages.map((message) => {
                    oldMessages.unshift(message)
                });
                setMessagePage(data.page)
                setMessages(oldMessages);
                setDisplayPrevMessageBtn(true);
            } else {
                setDisplayPrevMessageBtn(false);
            }
        });
    }

    let uploadFile = (e) => {
        if (e.target.files.length <= 0) {
            return false;
        }
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        formData.append('classId', classId);
        if (selectedChatMember != null) {
            formData.append('visible_only', selectedChatMember.id);
        }

        confirmAlert({
            title: 'Confirm to Upload',
            message: 'Are you sure to share ' + e.target.files[0].name,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setUploading(true);
                        Axios({
                            method: 'post',
                            headers: {
                                Authorization: 'Bearer ' + auth.authToken(),
                                'content-type': 'multipart/form-data'
                            },
                            data: formData,
                            url: env.baseUrl + "/message/upload",
                            onUploadProgress: (ev) => {
                                const progress = ev.loaded / ev.total * 100;
                                updateUploadProgress(Math.round(progress).toString());
                            },
                        }).then((resp) => {
                            setUploading(false);

                            setMessages(messages.concat(resp.data));
                            setTimeout(() => { scrollToBottom() }, 20)

                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => { return false; }
                }
            ]
        });


    }

    let toggleAudio = (e) => {
        e.preventDefault();
        let type = 'studentTurnOnAudio'
        if (audioStatus === 'on') {
            type = 'studentTurnOffAudio'
            setAudioStatus('off');
            webrtc.muteLocalMic()
            toast.success(languageContext.dictionary['micTurnOff'], {
                position: toast.POSITION.TOP_CENTER
            })
        } else {
            setAudioStatus('on')
            webrtc.unmuteLocalMic()
            toast.success(languageContext.dictionary['micTurnOn'], {
                position: toast.POSITION.TOP_CENTER
            })
        }

        channel.trigger("client-AskQuestion-" + classId, {
            type: type,
            name: auth.username(),
            videoStatus: videoStatus
        })
    }

    let muteLocalMic = () => {
        if (webrtc != undefined) {
            webrtc.muteLocalMic()
        }
    }

    let unmuteLocalMic = () => {
        if (webrtc != undefined) {
            webrtc.unmuteLocalMic()
        }
    }


    function reducer2(state, action) {
        switch (action.type) {
            case 'studentStream':
                // if (action.data !== 'listner') {
                //     let publishResponse = webrtc.publish(action.metadata.streamId)
                //     if (publishResponse !== 0) {
                //         if (!action.metadata.video) {
                //             setTimeout(() => {
                //                 webrtc.turnOffLocalCamera();
                //             }, 500)
                //         } else {
                //             webrtc.turnOnLocalCamera();
                //         }
                //     }
                // }
                return state = action.data;
            case 'studentStreamRemoved':
                return false;
            case "studentTurnOffVideo":
                webrtc.turnOffLocalCamera()
                return state;
            case 'studentTurnOnVideo':
                webrtc.turnOnLocalCamera()
                return state;
            case 'studentTurnOnAudio':
                webrtc.unmuteLocalMic()
                return state;
            case 'studentTurnOffAudio':
                webrtc.muteLocalMic()
                return state;
            default:
                return state
        }
    }

    let showHideChat = e => {
        e.preventDefault();
        setDisplayChatDot(false)

        $('.at-lacturechatscreen').toggleClass('at-closemessagesidebar');
    }

    let askQuestion = (e, icon) => {
        e.preventDefault();
        let withdraw = 0;

        if (icon == 'icon-info-1') {
            if (infoAsked) {
                setInfoAsked(false);
                withdraw = 1;
            } else {
                setInfoAsked(true)
            }
        } else {
            if (questionAsked) {
                setQuestionAsked(false);
                withdraw = 1;
            } else {
                setQuestionAsked(true);
            }
        }

        if (withdraw == 1) {
            toast.success(languageContext.dictionary['requestWithdrawn'], {
                position: toast.POSITION.TOP_CENTER
            });
        }

        console.log(auth.userInfo());

        channel.trigger("client-AskQuestion-" + classId,
            {
                user: auth.userInfo(),
                type: icon,
                withdraw: withdraw
            }
        )
    }

    let switchVideo = () => {
        var mediaConstraints = {
            video: true,
            audio: true
        };
        webrtc.switchVideoCameraCapture(studentStreamId, mediaConstraints)
    }

    let switchScreenShare = () => {
        webrtc.switchDesktopCapture(studentStreamId)

    }

    // let toggleScreenSharing = (e) => {
    // 	if (!screenSharing) {
    // 		setScreenSharing(true);
    // 		setVideoStatus('off');
    // 		switchScreenShare();
    // 	}else{
    // 		setVideoStatus('on')
    // 		setScreenSharing(false)
    // 		switchVideo();
    // 	}
    // }

    let screenShareCallBack = (message) => {
        if (message == 'allowed') {
            setScreenSharing(true);
        } else {
            setScreenSharing(false);
        }
    }

    let videoToggleWithCameraCallBack = (message) => {
        if (message == 'allowed') {
            setVideoStatus('on')
        }
    }


    let videoToggleWithoutCameraCallBack = (message) => {
        if (message == 'allowed') {
            setVideoStatus('off');
        }
    }

    let toggleScreenSharing = (e) => {
        // if (videoStatus == 'off') {
        //     alert("Please turn on your video");
        //     return;
        // }
        if (!screenSharing) {
            if (videoStatus == 'on') {
                webrtc.switchDesktopCaptureWithCamera(studentStreamId, screenShareCallBack)
            } else {
                webrtc.switchDesktopCapture(studentStreamId, screenShareCallBack)
            }

        } else {
            setVideoStatus('on')
            setScreenSharing(false)
            switchVideo();
        }
        console.log("Screen Sharing audioStatus" + audioStatus);
        if (audioStatus && (audioStatus == true || audioStatus == 'on')) {
            console.log("Screen Sharing audioStatus inside condition" + audioStatus);
            try {
                setTimeout(() => {
                    webrtc.unmuteLocalMic();
                }, 2000);
            } catch (error) {
                console.error('Error unmuting microphone immediately:', error);
            }
        }
    }

    let toggleVideo = (e) => {

        e.preventDefault();
        let type = 'studentTurnOffVideo';
        if (screenSharing) {
            if (videoStatus === 'on') {
                setVideoStatus('off');
                type = 'studentTurnOnVideo'
                webrtc.switchDesktopCapture(studentStreamId, videoToggleWithoutCameraCallBack)

            } else {
                type = 'studentTurnOnVideo'
                setVideoStatus('on')
                webrtc.switchDesktopCaptureWithCamera(studentStreamId, videoToggleWithCameraCallBack)

            }
        } else {
            if (videoStatus === 'on') {
                type = 'studentTurnOffVideo'
                setVideoStatus('off');
                webrtc.turnOffLocalCamera()
                toast.success(languageContext.dictionary['cameraTurnOff'], {
                    position: toast.POSITION.TOP_CENTER
                })
            } else {
                type = 'studentTurnOnVideo'
                setVideoStatus('on')
                webrtc.turnOnLocalCamera()
                toast.success(languageContext.dictionary['cameraTurnOn'], {
                    position: toast.POSITION.TOP_CENTER
                })
            }
        }

        channel.trigger("client-AskQuestion-" + classId, {
            type: type,
            name: auth.username(),
            audioStatus: audioStatus
        })
    }

    let turnOnFullScreen = () => {

        setFullScreen({ type: 'setFullScreen', data: 1 })
    }

    let turnOffFullScreen = e => {
        setDisplayChatDot(false)
        setFullScreenChat(false);

        setFullScreen({ type: 'setFullScreen', data: 0 })
    }


    let classEnded = () => {
        // var iframe = document.getElementById('teacherVideo');
        // iframe.src = "";
        $('.overlapteachervideo').css('display', 'block')
        $('.teacherstream').css('visibility', 'hidden')
        $('.studentActionButton').css('visibility', 'hidden')
        $('.recordingIcon').css('visibility', 'hidden')

    }

    let classStreamRestart = () => {
        // var iframe = document.getElementById('teacherVideo');
        // iframe.src = "https://corsi.stream:5443/WebRTCAppEE/play_embed.html?name=" + classId;
        $('.overlapteachervideo').css('display', 'none')
        $('.teacherstream').css('visibility', 'visible')
        $('.studentActionButton').css('visibility', 'visible')

    }

    let showFullScreenChat = e => {
        e.preventDefault();
        console.log('clicked');
        if (fullScreenChat) {
            setFullScreenChat(false);

        } else {
            setFullScreenChat(true);
        }
    }

    let restartClassStream = () => {
        console.log('test');
        setStreamRestart(true);

        setTimeout(() => {
            setStreamRestart(false)
        }, 1500)
    }

    // Check WebRTC permissions before initiating screen sharing
    function checkAudioPermissions() {
        return navigator.mediaDevices.getUserMedia({ audio: true })
            .then(() => {
                console.log('Audio permission granted');
                return true;
            })
            .catch((error) => {
                console.error('Audio permission denied:', error);
                return false;
            });
    }

    return (
        <>
            <ClassRoomLoader style={loading ? { display: 'block' } : { display: 'none' }} />
            <div className={fullScreenChat ? "at-lacturechatscreen at-chatfullscreen" : "at-lacturechatscreen at-closemessagesidebar"} style={loading ? { display: 'none' } : { display: 'block' }}>
                <ToastContainer />
                <PermissionModal
                    presenseChannel={channel}
                    classId={classId}
                    setStudentStreamStatus={setStudentStreamStatus}
                    setAudioStatus={setAudioStatus}
                    setVideoStatus={setVideoStatus}
                    memberStreamStatus={memberStreamStatus}
                />
                <OpenStreamPermissionmodel
                    presenseChannel={channel}
                    classId={classId}
                    webrtc={webrtc}
                    setClassMembers={setClassMembers}
                    memberStreamStatus={memberStreamStatus}
                    setMemberStreamStatus={setMemberStreamStatus}
                />
                <StartupModal startStudentStream={startStudentStream} />
                <RecordingStarted />
                <div id="at-sidebarchat" className="at-sidebarchat">
                    <div className="at-logoarea">
                        <strong className="at-logo short-logo">
                            <a href="#"><img src={logo} alt="Logo Image" /></a>
                        </strong>
                    </div>
                    <div className="at-chathead">
                        <h2><Text tid="chat" /></h2>
                        <a id="at-btnhidshowchat" className="at-btnhidshowchat" href="#" onClick={e => showHideChat(e)}>
                            <i className="icon-chat" onClick={scrollToBottom}></i>
                            {displayChatDot == true ? <span className="dot-icon">.</span> : ''}
                        </a>
                    </div>
                    {/* <div className="at-chaticonholder">
                        <i className="icon-chat"></i>

                    </div> */}
                    <div className="at-chatmessagearea" id="chatArea" ref={el => chatAreaEl = el}>
                        <div className="at-classmateschatarea">
                            {displayPrevMessageBtn ? (
                                <div className="at-messagetime">
                                    <Link to="" onClick={(e) => fetchPreviousMessages(e)} ><span><Text tid="loadPreviousMessage" /></span></Link>
                                </div>
                            ) : ''}
                            {displayMessageLoader ? (
                                <div style={{ textAlign: "center" }}>
                                    <img src={require('../../assets/loader.gif')} style={{ width: "30px" }} />
                                </div>
                            ) : ''}
                            {messages.map((message, i) => {
                                let devider = null;
                                if (messages[i - 1] !== undefined && message.date !== messages[i - 1].date) {
                                    if (message.date === dateToday) {
                                        devider = 'Today'
                                    } else {
                                        devider = message.date;
                                    }
                                }
                                return (
                                    <MessageHolder key={i}
                                        devider={devider}
                                        message={message}
                                        chatUser={chatUser}
                                        isSameUser={i !== 0 && messages[i - 1].userId === message.userId}
                                    />
                                )
                            })}
                        </div>

                        {/* <div className="at-themenodata">
						<i className="icon-chat"></i>
						<p>It's nice to start conversation with someone!</p>
						<h3>Let's Start</h3>
					</div> */}

                    </div>
                    <div className="at-chatfooter">
                        {uploading ?
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{ width: uploadProgress + "%" }} aria-valuenow={uploadProgress} aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            : ''
                        }
                        <form className="at-formtheme at-formwritemessage" onSubmit={(e) => handleMessageSubmit(e)}>
                            <fieldset>
                                <div className="form-group">
                                    <span className="icon-text"></span>
                                    <input placeholder={selectedChatMember != null ? languageContext.dictionary['sentMessageVisible'] + " " + selectedChatMember.name : languageContext.dictionary['typeMessage']} value={message} onChange={(e) => setMessage(e.target.value)} />
                                    <ul className="at-bgchatattachopen">
                                        <li>
                                            <div className="at-themeattachfile">
                                                <input type="file" name="select-file" value="" id="at-attachefile" onChange={(e) => uploadFile(e)} />
                                                <label htmlFor="at-attachefile"><i className="icon-attachment"></i></label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="at-classmatesdropdown">
                                                <div className="at-classmatesdropdownhead">
                                                    <h3><Text tid="classMates" /></h3>
                                                    <a id="at-closedrodown" className="at-closedrodown" href="#"><i className="icon-cancel"></i></a>
                                                </div>
                                                <ClassMemberList classMembers={classMembers} setSelectedChatMember={setSelectedChatMember} selectedChatMember={selectedChatMember} />
                                            </div>
                                            <a id="at-btnopenclassmatedropdown" className="at-btnopenclassmatedropdown" href="#"><i className="icon-dots"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </fieldset>
                        </form>

                    </div>
                </div>
                <div className="at-lacturechatscreencontent at-lessonscreencontentholder">
                    <header id="at-header" className="at-header">
                        <ul className="at-lessonnametimedate">
                            <li>
                                <i className="icon-lesson-name"></i>
                                <span>{virtualClass.title}</span>
                            </li>
                            {/* <li>
                            <i className="icon-calendar"></i>
                            <span>{virtualClass.date}</span>
                        </li>
                        <li>
                            <i className="icon-time"></i>
                            <span>{virtualClass.start_time} - {virtualClass.end_time}</span>
                        </li> */}
                        </ul>
                        <div className="at-rightarea">
                            <ProfileComponent />
                            <LangSelector />
                        </div>
                    </header>
                    <h2 className="overlapteachervideo" style={{ display: "none" }}><Text tid="streamEnded" /></h2>
                    <div className={`at-screencontentholder ${fullScreen && studentStreamEnabled ? splitScreenClass : ''} ${fullScreen ? "at-videofullscreen" : ""} ${studentStreamEnabled ? "studentStreamEnabled" : ""} ${showClasses ? 'at-showClasses' : ''}`}>
                        <div className={studentStreamEnabled ? "at-videosholder at-studentStreamAdded" : "at-videosholder"}>
                            <figure className="at-classmatescreenimg teacherstream teacherVideo">
                                <img className="recordingIcon live" style={{ visibility: 'hidden' }} src={require('../../assets/images/recording.jpg')} />
                                <div className="at-teachernotstartedtext">
                                    <p><Text tid="waitText" /></p>

                                </div>
                                <ul className="at-btnvideoshare">
                                    <li>
                                        <button className="at-btnshare at-btnfullscreen" onClick={e => turnOnFullScreen()}><i className="icon-add-picture"> </i></button>
                                        <button className="at-btnexitfullscreen at-btnshare" onClick={e => turnOffFullScreen(e)}><i className="icon-cancel"></i></button>
                                    </li>
                                </ul>
                                {
                                    fullScreen ?
                                        <a
                                            id="at-btnhidshowchat"
                                            className="at-btnhidshowchat"
                                            href="#"
                                            style={{ zIndex: 9999 }}
                                            onClick={e => showFullScreenChat(e)}
                                        >
                                            <i className="icon-chat"></i>
                                            {(displayChatDot) ? <span className="dot-icon">.</span> : ''}
                                        </a> : ''}
                                {/* <iframe id="teacherVideo" width="100%" height="100%" src={"https://corsi.stream:5443/WebRTCAppEE/play_embed_teacher.html?name=" + classId} controls="0" frameborder="0" allowfullscreen autoplay></iframe> */}
                                {streamRestart == true ? (<span className="at-re-connectstream">Reconnecting Stream</span>) : (<AntPlayer streamId={classId} classStreamRestart={classStreamRestart} classStream={true} studentStreamUser={teacherStreamUser} />)}
                            </figure>
                            <figure className="at-classmatescreenimg at-buttonsholder" style={studentStreamEnabled && studentStreamEnabled === 'streamer' ? { display: 'block' } : { display: 'none' }}>
                                <video id="localVideo" autoPlay muted playsInline />
                                <div style={videoStatus == 'off' && !screenSharing ? { visibility: 'visible' } : { visibility: 'hidden' }}>
                                    <Avatar src={require('../../assets/images/class-meates/img-01.png') + "s"} size="200px" style={{ "maxWidth": "240px", height: "180px" }} name={auth.username()} />
                                </div>
                                <ul className="at-btnvideoshare">
                                    <li>
                                        <button className={`at-btnshare ${audioStatus == "off" ? " mic-mute" : "mic active"}`} onClick={(e) => toggleAudio(e)}>
                                            <i className="icon-mic"> </i>
                                            {audioStatus == "off" ?
                                                (<span className="at-themetooltip">
                                                    <Text tid="openYourMic" />
                                                </span>)
                                                :
                                                (<span className="at-themetooltip">
                                                    <Text tid="closeYourMic" />
                                                </span>)}
                                        </button>
                                    </li>
                                    <li>
                                        <button className={`at-btnshare ${videoStatus == "on" ? 'active' : ''}`} onClick={(e) => toggleVideo(e)}>
                                            <i className={videoStatus == "off" ? "icon-video-cancel" : "icon-video"}></i>
                                            {videoStatus == "off" ?
                                                (<span className="at-themetooltip">
                                                    <Text tid="openYourVideo" />
                                                </span>)
                                                :
                                                (<span className="at-themetooltip">
                                                    <Text tid="closeYourVideo" />
                                                </span>)}
                                        </button>
                                    </li>

                                    <li>
                                        <button className={`at-btnshare at-btnsharescreen ${screenSharing ? 'active' : ''}`} onClick={(e) => toggleScreenSharing(e)}>
                                            <i className="icon-display"></i>
                                            <span className="at-themetooltip">
                                                {screenSharing ? <Text tid="turnOffScreenSharing" /> :
                                                    <Text tid="shareScreen" />
                                                }
                                            </span>
                                        </button>
                                    </li>
                                    <li>
                                        <button className="at-btnshare at-btnfullscreen" onClick={e => turnOnFullScreen()}>
                                            <i className="icon-add-picture"> </i>
                                            <span className="at-themetooltip">
                                                <Text tid="enlargeToFull" />
                                            </span>
                                        </button>
                                        <button className="at-btnexitfullscreen at-btnshare" onClick={e => turnOffFullScreen(e)}>
                                            <i className="icon-cancel"></i>
                                            <span className="at-themetooltip">
                                                <Text tid="exitFull" />
                                            </span>
                                        </button>
                                    </li>

                                </ul>
                            </figure>
                            {studentStreamEnabled && studentStreamEnabled === 'listner' ? (

                                <figure className="at-classmatescreenimg">
                                    <div style={videoStatus == 'off' ? { visibility: 'visible' } : { visibility: 'hidden' }}>
                                        <Avatar src={require('../../assets/images/class-meates/img-01.png') + "s"} size="200px" style={{ "maxWidth": "240px", height: "180px" }} name={studentStreamUser.name} />
                                    </div>
                                    {/* <iframe width="50%" height="100%" src={"https://corsi.stream:5443/WebRTCAppEE/play_embed_student.html?name=" + studentStreamId} frameborder="0" allowfullscreen></iframe> */}
                                    <AntPlayer streamId={studentStreamId} studentStreamUser={studentStreamUser} />
                                </figure>) : ''
                            }
                            <div className="at-fullscreenbuttons">
                                {
                                    fullScreen ?
                                        <a
                                            id="at-btnhidshowchat"
                                            className="at-btnhidshowchat"
                                            href="#"
                                            style={{ zIndex: 9999 }}
                                            onClick={e => showFullScreenChat(e)}
                                        >
                                            <span class="at-themetooltip">Click To See Messages</span>
                                            <i className="icon-chat"></i>
                                            {(displayChatDot) ? <span className="dot-icon">.</span> : ''}
                                        </a> : ''}
                                <button className="at-btnswitchview at-btnclasses" onClick={e => turnOffFullScreen()}>
                                    <span class="at-themetooltip">Click To See Classes</span>
                                    Classes
                                </button>
                                <button className="at-btnswitchview" onClick={() => setSplitScreenClass('at-teacherview')}>
                                    <img src={teacherIcon} alt="Teacher Icon" />
                                    <span class="at-themetooltip">Click To Teacher View</span>
                                </button>
                                {classMembers.length > 0 && classMembers.filter(function (member) { return member.online == 1 }).length > 1 ? (<>
                                    <button className="at-btnswitchview" onClick={() => setSplitScreenClass('at-teacherstudentview')}>
                                        <span class="at-themetooltip">Click To Teacher Student View</span>
                                        <img src={teacherIcon} alt="Teacher Icon" />
                                        <i className="icon-user"></i>
                                    </button>
                                    <button className="at-btnswitchview" onClick={() => setSplitScreenClass('at-studentview')}>
                                        <span class="at-themetooltip">Click To Student View.</span>
                                        <i className="icon-user"></i>
                                    </button></>) : ''}
                            </div>

                        </div>
                        <div className="at-classattandantholder at-student-attandars">
                            <span className="at-classesheading">Classes</span>
                            <button className="at-btnhideclasses" onClick={() => setShowClasses(!showClasses)}><i className="icon-embed2"></i></button>
                            <div className="at-sectiontitle">
                                <h3>Class
                                    <i className="icon-history" onClick={restartClassStream}></i>
                                    <span className="at-themetooltip">
                                        <Text tid="reconnectStream" />
                                    </span>
                                </h3>
                                <span className="at-onlinemates"><Text tid="online" /> <strong>{onlineMembers}</strong>/{classMembers.length}</span>
                            </div>
                            <div className="at-onlineclassmatesholder">
                                {classMembers.map((member) => {
                                    return (<ClassMemberHolder member={member}
                                        webrtc={webrtc}
                                        setClassMembers={setClassMembers}
                                        presenceChannel={channel}
                                        memberStreamStatus={memberStreamStatus}
                                        studentStreamEnabled={studentStreamEnabled}
                                        studentStreamId={studentStreamId}
                                        setMemberStreamStatus={setMemberStreamStatus}
                                    />)
                                })}
                            </div>
                        </div>
                        <div className="at-callbuttonstimer" style={studentStreamEnabled && studentStreamEnabled === 'streamer' ? { display: 'none' } : { display: 'block' }}>
                            {/* <ReactTooltip place="top" type="dark" effect="float" /> */}
                            <div className="at-btnsholder">
                                <a onClick={e => askQuestion(e, 'icon-info-1')} className="at-btnthemevideo studentActionButton" href="" style={{ visibility: 'hidden' }}>
                                    <span className={infoAsked ? "infoAsked" : ""}>
                                        {/* <i className="icon-info"></i> */}
                                        <img src={answer_question_image} style={{ width: "70px", margin: "-4px 0 0" }} />
                                    </span>
                                    <span class="at-themetooltip">{infoAsked ? languageContext.dictionary['withdrawnRequest'] : languageContext.dictionary['answerQuestion']}</span>
                                </a>

                                <a onClick={e => askQuestion(e, 'icon-question-mark-1')} className="at-btnthemevideo studentActionButton" href="" style={{ visibility: 'hidden' }}>
                                    <span className={questionAsked ? "infoAsked" : ""}>
                                        {/* <i className="icon-question-mark"></i> */}
                                        <img src={ask_question_image} style={{ width: "70px", margin: "-4px 0 0" }} />
                                    </span>
                                    <span class="at-themetooltip">{questionAsked ? languageContext.dictionary['withdrawnRequest'] : languageContext.dictionary['askedQuestion']}</span>
                                </a>
                                {/* <a onClick={e => muteTeacherVideo(e)} className="at-btnthemevideo" href=""><span><i className="icon-sound"></i></span></a>
                                <a className="at-btnthemevideo" href="javascript:void(0);"><span><i className="icon-video"></i></span></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StudentStartLesson;
