import React from 'react';
import ContentLoader from "react-content-loader"


const ClassRoomLoader = ( props ) => {
    return (
      <ContentLoader 
      speed={2}
      width={4000}
      height={768}
      viewBox="0 0 4000 768"
      backgroundColor="#f3f3f3"
      foregroundColor="#5e5a5a"
      {...props}
    >
      
      <rect x="20" y="20" rx="0" ry="0" width="157" height="60" /> 


      <rect x="67" y="334" rx="3" ry="3" width="88" height="10" /> 
      <circle cx="37" cy="351" r="20" /> 
      <rect x="67" y="355" rx="3" ry="3" width="110" height="10" /> 
      <rect x="67" y="375" rx="3" ry="3" width="130" height="10" /> 
      
      <circle cx="37" cy="177" r="20" /> 
      <rect x="67" y="157" rx="3" ry="3" width="88" height="10" /> 
      <rect x="67" y="178" rx="3" ry="3" width="110" height="10" /> 
      <rect x="67" y="196" rx="3" ry="3" width="130" height="10" /> 
            
      <rect x="67" y="242" rx="3" ry="3" width="88" height="10" /> 
      <rect x="67" y="261" rx="3" ry="3" width="110" height="10" /> 
      <rect x="67" y="282" rx="3" ry="3" width="130" height="10" /> 
      <circle cx="37" cy="268" r="20" />



      {/* <rect x="67" y="84" rx="3" ry="3" width="88" height="10" /> 
      <circle cx="37" cy="101" r="20" /> 
      <rect x="67" y="105" rx="3" ry="3" width="110" height="10" /> 
      <rect x="67" y="125" rx="3" ry="3" width="130" height="10" /> 
      
      <circle cx="37" cy="177" r="20" /> 
      <rect x="67" y="157" rx="3" ry="3" width="88" height="10" /> 
      <rect x="67" y="178" rx="3" ry="3" width="110" height="10" /> 
      <rect x="67" y="196" rx="3" ry="3" width="130" height="10" /> 
            
      <rect x="67" y="242" rx="3" ry="3" width="88" height="10" /> 
      <rect x="67" y="261" rx="3" ry="3" width="110" height="10" /> 
      <rect x="67" y="282" rx="3" ry="3" width="130" height="10" /> 
      <circle cx="37" cy="268" r="20" /> */}


      <rect x="279" y="118" rx="0" ry="0" width="1280" height="303" />
      <rect x="280" y="448" rx="0" ry="0" width="200" height="115" /> 
      <rect x="490" y="448" rx="0" ry="0" width="200" height="115" /> 
      <rect x="700" y="448" rx="0" ry="0" width="200" height="115" /> 
      <rect x="910" y="448" rx="0" ry="0" width="200" height="115" /> 
      <rect x="1120" y="448" rx="0" ry="0" width="200" height="115" /> 
      <rect x="1330" y="448" rx="0" ry="0" width="200" height="115" /> 
      
      <rect x="280" y="19" rx="0" ry="0" width="900" height="21" /> 
      <rect x="280" y="57" rx="0" ry="0" width="800" height="21" /> 

      
      <rect x="24" y="510" rx="0" ry="0" width="139" height="10" /> 
      <rect x="24" y="532" rx="0" ry="0" width="174" height="10" /> 
      <rect x="24" y="551" rx="0" ry="0" width="220" height="10" /> 
    </ContentLoader>
    );
};

export default ClassRoomLoader;