import React from 'react';
import {Route, Redirect, useParams} from 'react-router-dom';
import auth from './authinfo'

function PrivateRoute({ children, ...rest }) {
    
    return (
      <Route
        {...rest}
        render={({ location }) =>
          auth.isAuthenticated() && auth.isStudent() ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }

export default PrivateRoute;