const it = {
    main: 'This is Italian Text',
    chat : 'Chat',
    selectLang : 'italiano',
    class : 'Classe',
    classMates : 'Compagne di classe',
    typeMessage : 'Scrivi un messaggio qui',
    sorry : 'Scusate',
    linkNotAvailable : 'Questo non è un link valido!',
    openValidLessonlink : 'Si prega di aprire un collegamento lezione valido',
    welcomeTo : 'Piattaforma per i Corsi Online',
    weMakeEasy : 'Rendiamo facile per tutti imparare online!',
    useYouraccount:'Utilizza il tuo account Portalecorsi per accedere:',
    password : "Password",
    forgotPassword : 'www.portalecorsi.com',
    youforgotPassword : 'Hai dimenticato la password? Richiedila su',
    signIn : 'Entra',
    dontHaveAccount : 'Iscrizioni ai corsi su',
    signUp : 'www.portalecorsi.com',
    lesson : 'Lezione',
    willStartIn : 'inizierà tra',
    minutes : 'minuti',
    enterIntoLesson : 'Entra in classe',
    everyOne : 'Tutte',
    wantToAsk : 'voglio chiedere qualcosa',
    permission : 'Autorizzazione',
    wantToAskQuestion : 'vuole fare una domanda',
    wantToAnswerQuestion : 'vuole rispondere alla tua domanda',
    online : "in linea",

    requestGanted : "Richiesta di fare domanda concessa",
    requestReceived : "Richiesta ricevuta dall'insegnante",
    requestDenied : "Richiesta di porre domanda negata",
    confirmUpload : "Conferma per caricare",
    sureToShare : "Sei sicuro di condividere",
    requestWithdrawn : "Richiesta ritirata",
    loadPreviousMessage : "Carica messaggi precedenti",
    streamEnded : "Streaming terminato",
    withdrawnRequest : "Ritira richiesta",
    askedQuestion : "Fai una domanda all'insegnante",
    answerQuestion : "Rispondi alla domanda",
    startStream : "Fare clic sul pulsante per avviare lo streaming  ",
    sentMessageVisible : "Scrivi un messaggio privato a",

    sureToStopLesson : "Sei sicuro di voler interrompere la lezione?",
    youCanAlwaysResume : "Puoi sempre riprendere",
    streamStarted : "Streaming avviato",
    cameraTurnOff : "Videocamera spenta",
    cameraTurnOn : "Videocamera accesa",
    micTurnOff : "Microfono spento",
    micTurnOn : "Microfono acceso",
    itsNiceToStartCon : " È bello iniziare una conversazione con qualcuno!",
    letsStart : "Inizia",

    openYourMic : "Il tuo microfono è chiuso, per aprire il microfono (e farti sentire dalla classe) clicca questo bottone",
    closeYourMic : "Chiudi il microfono, la classe non ti sentirà più.",
    openYourVideo : "Apri il tuo video, così la classe può vederti.",
    closeYourVideo: "Chiudi il video, la classe non ti vedrà più.",
    enlargeToFull : "Ingrandisci a schermo intero.",
    exitFull : "Esci dalla modalità schermo intero.",
    shareScreen : "Condividi il tuo schermo",
    turnOffScreenSharing : "Disattiva la condivisione dello schermo",

    openCameraOfStudent :  "Porta questo studente alla lavagna, la classe potrà vedervi entrambi sullo schermo principale. ",


    openTheStudentVideo : "Apri sia il video che l'audio dello studente",
    openTheStudentAudio : "Apri solo l'audio dello studente",
    rejectTheRequest : "Rifiuta la richiesta",

    expellStudent : "Espelli questo studente dalla classe",
    openStudentMicroPhone : "Apri il microfono di questo studente, così tutta la classe sentirà la sua voce.",
    teacher: "L’insegnante",
    wouldLikeToComeBoard : "vorrebe farti venire alla lavagna: la classe potrà vedervi entrambi sullo schermo principale.",
    acceptAndStartVideo : "Accetta (apri video e audio)",
    refuse : "Rifiuta",
    waitText: "LA LEZIONE DEVE ANCORA INIZIARE",
    zoomStudentvideo: "Espandi il video di questo studente a schermo intero (visibile solo a te)",
    closeZoomStudentVideo : "Esci dalla modalità schermo intero",
    closeStudentvideo: "Spegni il video",
    removeStudent: "Chiudi",
    studentStarupmodal: "Stai per entrare in classe. Aspetta che l'insegnante dia inizio al video della lezione.",
    studentCameramic: "Il tuo microfono e la tua videocamera saranno inizialmente chiusi, se vuoi aprirli, fai clic su questi pulsanti della tua casella.",
    toOPenmicrophone: "Per aprire il tuo Microfono",
    toOpencamera: "Per aprire il tuo Video",
    enterTheclass: "OK? ENTRA IN CLASSE!",
    allow : "Permetti",
    wantToTurnOnAudio : "vuole attivare l'audio",
    wantToTurnOnVideo : "vuole attivare il tuo video",

    openVideo : "Apri video",
    closeVideo : "Chiudi il video",
    openMic : "Apri il microfono",
    closeMic : "Chiudi il microfono",
    InterNetgone : "La tua connessione è troppo lenta",
    closeTheMicForALlStudents : "Chiudi il microfono per tutti gli studenti. Non potranno parlare finché non lo riaprirai di nuovo.",
    openTheMicForAllStudents : "Apri il microfono per tutti gli studenti. Possono parlare",
    reconnectStream : "Se ricevi il video/audio in ritardo, clicca su questo pulsante per rimetterti in pari.",
    PleaseEnterEmail : "Inserisci il tuo indirizzo email:",
    SendPassword : "Invia password",
    EnterNewPassword : "Inserire una nuova password:",
    SubmitPassowrd : "Submit",
    useChromeBrowser : 'Usa questa piattaforma con Google Chrome',
    downloadChromeTitle : 'Per utilizzare questa piattaforma è necessario utilizzare Google Chrome.',
    downloadChromeText : "Per favore apri la pagina utilizzando Chrome. Se non hai Chrome, clicca qui per installarlo ora (Non preoccuparti, è molto facile)",
    recordingStarted : "L'insegnante sta iniziando la registrazione della lezione.Nota: la registrazione interessa solo la schermata principale, ovve-ro l'insegnante e l'allievo eventualmente chiamato alla lavagna.In ogni caso se non vuoi essere registrato puoi sempre tenere il microfono e il video chiusi.",
    gotIt : "HO CAPITO",
    cancel : "ANNULLA",
    startRecording : "AVVIA REGISTRAZIONE",
    recordingStartedAlert : "Nota: la registrazione coinvolge solo la lo schermo principale (insegnante e allievi chiamati alla lavagna).",
    maxStreamReached : "Al momento il tuo video non può essere riprodotto, troppi video aperti contemporaneamente nella classe rallenterebbero la tua connessione e la tua esperienza di streaming. Se vuoi fare una domanda all’insegnante, usa il pulsante Domanda",


  };
  
  export default it;