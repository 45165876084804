import { languageOptions, dictionaryList } from '../../lang/languages';
import React , {useContext, useState , createContext} from 'react'

// create the language context with default selected language
export const LanguageContext = createContext({
  language: languageOptions[0],
  dictionary: dictionaryList[languageOptions[0].id]
});

export function LanguageProvider(props) {
    const languageContext = useContext(LanguageContext);
    const [language, setLanguage] = useState(languageContext.language);
    const [dictionary, setDictionary] = useState(languageContext.dictionary);
  
    const provider = {
      language,
      dictionary,
      setLanguage: (selectedLanguage) => {
        setLanguage(selectedLanguage); // it will update the language in state
        setDictionary(dictionaryList[selectedLanguage.id]);
      }
    };
  
    return (
      <LanguageContext.Provider value={provider}>
        {props.children}
      </LanguageContext.Provider>
    );
  };