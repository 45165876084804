
class Auth {

    authenticate = (token,user) => {
      sessionStorage.setItem('isAuthenticated',true);
      sessionStorage.setItem('token',token);
      sessionStorage.setItem("user",JSON.stringify(user))
      if(user.role_id === 2){
        sessionStorage.setItem("isTeacher",true)
      }else if(user.role_id === 3){
        sessionStorage.setItem("isStudent",true);
      }
    }
  
    isAuthenticated = () => {
      return (sessionStorage.getItem('isAuthenticated') != undefined && sessionStorage.getItem('isAuthenticated') == 'true')
  
    }
  
    username = () => {
      let user = JSON.parse(sessionStorage.getItem("user"))
      
      return user.name;
    }

    userInfo = () => {
      let user = JSON.parse(sessionStorage.getItem("user"))
      
      return user;
    }
  
    userId = () => {
      let user = JSON.parse(sessionStorage.getItem("user"))
      
      return user.id;
    }

    isTeacher = () => {
      return (sessionStorage.getItem("isTeacher") != undefined && sessionStorage.getItem("isTeacher") === 'true')

    }

    isStudent = () => {
      return (sessionStorage.getItem("isStudent") != undefined && sessionStorage.getItem("isStudent") === 'true')

    }

    authToken = () => {
      return sessionStorage.getItem('token')
    }
  
    signout = () => {
      sessionStorage.clear();
    }
  
  }
  
  const auth = new Auth();
  export default auth;
  