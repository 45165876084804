import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Redirect, Link } from 'react-router-dom'
import logo from '../assets/images/logo.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LangSelector from '../lang/LangSelector'
import Text from '../lang/Text';
import Axios from 'axios';
import env from '../env'

const ChangePassword = () => {
	const [isTokenValid,setIsTokenValid] = useState(false);
	const [password,setPassword] = useState('')
	const [passwordConfirm,setPasswordConfirm] = useState('')
	let { token } = useParams();
	let history = useHistory()

	useEffect(() => {
		Axios.post(env.baseUrl+'/verify/token/'+token,{token: token}).then( ({data}) => {
			if (data.status_code === 200){
				setIsTokenValid(true);
            }else{
				history.push('/')
            }
        }).catch(e => {});
	},[])


	let updatePassword = e => {

		e.preventDefault();
		
		if (password != passwordConfirm) {
			alert('Confirm password not matched')
			return 0;
		}

		Axios.post(env.baseUrl+'/update-password',{token: token,password : password,passwordConfirm : passwordConfirm}).then( ({data}) => {
			if (data.status_code === 200){
				localStorage.setItem("passwordUpdated", true);
				history.push('/')
            }else{
				toast.error('Invalid token', {
                    position: toast.POSITION.TOP_RIGHT
                  });
			}
        }).catch(e => {});

	}

    return (
		isTokenValid ? 
        <div>
            <ToastContainer />
            <div className="at-signinpageholder">
			<div className="at-singinpageimg"></div>
			<div className="at-singinpage">
				<div className="at-pagehead">
					<div className="at-logoimg">
						<img src={logo} alt="logo imag"/>
					</div>
					<LangSelector />
				</div>
				<div className="at-pageheading">
                <h3><em>Portale Corsi</em> <Text tid="welcomeTo" /></h3>
				</div>
				<div className="at-signinpageform">
					<form className="at-formtheme" onSubmit={updatePassword}>
						<fieldset>
                            <legend><Text tid="EnterNewPassword"/></legend>
							<div className="form-group">
								<label>New Password</label>
								<input type="Password" value={password} onChange={e => setPassword(e.target.value)} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" placeholder="Password at least 8 character" min="8" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required/>
								<i className="icon-lock"></i>
							</div>
							<div className="form-group">
								<label>Confirm New Password</label>
								<input type="Password" value={passwordConfirm} onChange={e => setPasswordConfirm(e.target.value)} placeholder="Confirm New Password" required/>
								<i className="icon-lock"></i>
							</div>
							{/* <div className="form-group">
								<label><Text tid="password" /></label>
								<input type="password" name="password" placeholder="••••••••••"/>
								<i className="icon-lock"></i>
							</div> */}
							{/* <a className="at-btnforgot" href="javascript:void(0);"><Text tid="forgotPassword" /></a> */}
							<div className="form-group">
								<button className="at-signin" type="submit"><Text tid="SubmitPassowrd" />
								</button>
							</div>
							{/* <div className="form-group">
								<span className="at-btnsingup"><Text tid="dontHaveAccount" /><a href="signup.html"> <Text tid="signUp" /></a></span>
							</div> */}
						</fieldset>
					</form>
					<span className="at-copyright">© Copyright 2020</span>
				</div>
			</div>
		</div>
        </div> : ''
    );
};

export default ChangePassword;