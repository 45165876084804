import React, { useEffect, useState } from 'react';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom'
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import $ from 'jquery'
import Pusher from 'pusher-js';
import env from '../../env';
import auth from '../../Auth/authinfo'
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LangSelector from '../../lang/LangSelector';
import Text from '../../lang/Text';

const StartLesson = (props) => {
    let {classId} = useParams();
    let history = useHistory();
    const [mediaDevicesStatus, setMediaDevicesStatus] = useState({audio: false,video:false})
    const [duration , setDuration] = useState(0)
    const [lessonDate , setLessonDate] = useState('')

    
    let setVideoSrc = () => {
        let cameraPreview = document.getElementById("cameraPreview");

        let mediaConstraints = {
            audio: true,
            video: true
        };

        navigator.mediaDevices.getUserMedia(mediaConstraints).then((stream) => {
            mediaDevicesStatus.video = true;
            mediaDevicesStatus.audio = true;
            setMediaDevicesStatus(mediaDevicesStatus)
            cameraPreview.srcObject = stream;
            cameraPreview.play()
        }).catch(e => {
            console.log(e);
            navigator.mediaDevices.getUserMedia(mediaConstraints);
        });
    }

    let allowDevices = () => {
        
    }

    const renderTime = value => {

        if (value === 0) {
          return <div className="timer">Next Lesson <br></br> {lessonDate}</div>;
        }

        var hours = new Date(value * 1000).toISOString().substr(11, 8)        ;
        // var minutes = Math.floor(value / 60);
        // var seconds = value - minutes * 60;
        return (
          <div>
              <span style={ {textAlign:"center" }}><Text tid="lesson" /> <span><Text tid="willStartIn" /></span></span>
            <div className="text" style={ {textAlign:"center", fontSize:"30px" }}>{ hours}</div>
            <span style={ {textAlign:"center" }}><Text tid="minutes" /></span>
          </div>
        );
      };

      let enterIntoLesson = (event) => {
          event.preventDefault();

          if(mediaDevicesStatus.audio && mediaDevicesStatus.video){
              history.push("/class/"+classId)
          }else{
              alert('Please Make sure your camera and mic properly connected and you allowed access in browser')
              setVideoSrc()
          }
      }

    useEffect(() => {      
        setVideoSrc();
        allowDevices()
        Axios.get(env.baseUrl + "/class/info/" + classId, {
			'headers': {
				Authorization: 'Bearer ' + auth.authToken(),
				'content-type': 'multipart/form-data'
			},
		}).then(({ data }) => {
			if (data.enrolled_status) {
                setDuration(data.time)
                if(data.time < 0){
                    setLessonDate(data.date)
                }
                const pusher = new Pusher(env.PUSHER_APP_KEY, {
                    cluster: env.PUSHER_APP_CLUSTER,
                    forceTLS: true,
                    auth: {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + auth.authToken()
                        }
                    },
                    userId: auth.userId(),
                    userInfo: {},
                    authEndpoint: env.baseUrl + "/pusher/auth"
                });

                const authChannel = pusher.subscribe('AuthUser' + auth.userId());

                authChannel.bind('Logout',function(){
                    window.sessionStorage.clear();

                    if(classId !== undefined){
                        window.location.replace('/'+classId)
                    }else {
                        window.location.replace('/')            
                    }
                });
			} else {
				history.push('/')
			}
		}).catch(e => console.log(e));

    }, []);
    return (
        <div className="at-startupscreenarea">
            <ToastContainer />
            <div className="at-startupcontentholder">
                <LangSelector />
                <div className="at-switchcamera">
                    <div className="at-startupcontent at-cameraon">
                        <video src="" id="cameraPreview" height="200" width="200" muted></video>
                        <span className="at-selectimageicon">
                            <i className="icon-add-picture"></i>
                            <h2>Switch on you camera and add your image!</h2>
                        </span>
                    </div>
                </div>
                
                    { duration > 0 ?  (<div className="at-lessionstarttimer">
                            <CountdownCircleTimer
                                class={'circles-container'}
                                isPlaying
                                durationSeconds={duration}
                                colors={[["#ff9724", 0.33], ["#ff6600", 0.33], ["#ff6600"]]}
                                renderTime={renderTime}
                                onComplete={() => [true, 1000]}
                            />
                            </div>) 
                    : ''}


                    { duration < 0 ?  (<div className="at-lessionstarttimer">
                            <CountdownCircleTimer
                                class={'circles-container'}
                                isPlaying={false}
                                durationSeconds={0}
                                colors={[["#ff9724", 0.33], ["#ff6600", 0.33], ["#ff6600"]]}
                                renderTime={renderTime}
                                onComplete={() => [true, 1000]}
                            />
                            </div>) 
                    : ''}

                <div className="at-btnholder">
                    <Link to="/class/:id" className="at-btnenterlesson" onClick={e => enterIntoLesson(e)}><span><Text tid="enterIntoLesson" /></span></Link>
                </div>
            </div>
        </div>
    );
};

export default StartLesson;
