import React from 'react';
import Text from '../../lang/Text'
import { Offline, Online, Detector } from "react-detect-offline";



const NoInternet = () => {
    return (
        <Detector
  render={({ online }) => (
      !online ? (
        <div class="at-alertinternetconnecion alert alert-danger alert-dismissible fade show" role="alert">
            <Text tid="InterNetgone"/>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>) : ''
  )}
/>
    );
};

export default NoInternet;