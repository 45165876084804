import React from 'react';
import Text from '../../lang/Text'

const MaxStreamReached = () => {
    return (
        <div className="at-thememodal modal at-downloadChrome fade" id="at-maxStreamReached" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
            <div className="modal-dialog at-modaldialog" role="document">
                <div className="modal-content at-modalcontent">
                    <div className="modal-body at-modalbody">
                        <button type="button" data-tip="Close" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="at-startupmodalcontentholder">
                            <div className="at-startupmodalcontent">
                                <div className="at-description">
                                    <p>Max Stream</p>
                                    <p><Text tid="maxStreamReached" /></p>
                                </div>
                                <a  className="at-btnthemevideo studentActionButton">
                                    <span className="">
                                        <img src={require('../../assets/images/ask-question.svg')} style={{ width: "70px", margin: "-4px 0 0"}} />
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MaxStreamReached;